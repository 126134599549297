.ui-overlay {
  top: 0;
  position: fixed;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;

  width: 100%;
  height: 100%;
  
  background-color: rgba(1, 1, 1, 0.5);
  
  z-index: 10;
}
