#pedidos-tela {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: auto;
}

#pedidos-tela > .button {
  font-size: 1.25em;

  margin-bottom: 1em;
}

#pedidos-tela .voltar-tela-produtos {
  font-size: 1em;
  font-family: 'Roboto';
  padding: 0.5em;
  margin: 0.8em 0 0.8em 0;
}