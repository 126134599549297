#production-card-cell {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
}

#production-card-cell .dog-ear {
  position: relative;
  right: 0;
  top: 0;

  align-self: flex-end;

  width: 0;
  height: 0;
  
  border-style: solid;
  border-width: 25px 0 0 25px;
  border-color: white white white rgb(172, 205, 255);
}

#production-card-cell > .card {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  border-radius: var(--default-border-radius-card);

  height: 100%;
}

#production-card-cell > .card > .card-content {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;

  height: 100%;
}

#production-card-cell > .card > .card-content .linha {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
}

#production-card-cell > .card > .card-content .linha.no-grow {
  flex: 0 1 auto;
}

#production-card-cell > .card > .card-content .linha.alinhada-a-direita {
  justify-content: flex-end;
}

#production-card-cell > .card > .card-content .codigo-origem {
  height: 1.5em;

  background: rgb(230, 230, 230);

  color: black;
  font-size: 0.8em;
  font-weight: 500;
  min-width: 2.2em;

  text-align: center;
  padding-right: 0.2em;
  margin-right: 0.2em;

  border-radius: 0.2em;
}

#production-card-cell > .card > .card-content .numero-pedido {
  min-width: 8.2em;
  height: 1.5em;

  background: rgb(230, 230, 230);
  
  color: black;
  font-size: 0.8em;
  font-weight: 500;

  text-align: right;
  white-space: nowrap;
  padding-right: 0.2em;

  border-radius: 0.2em;
}

#production-card-cell > .card > .card-content .nome-cliente {
  width: 100%;

  font-size: 0.7em;
  font-weight: 500;
  color: white;

  padding-left: 0.5em;

  min-width: 3.2em;
  height: 1.5em;
  border-radius: 0.2em;
  margin-left: 0.5em;
}

#production-card-cell > .card > .card-content .quantidade-itens {
  font-weight: 500;
  font-size: 1.2em;
  color: white;
  margin-left: 0.2em;
  margin-right: 0.8em;
  margin-top: 0.6em;
  white-space: nowrap;
}

#production-card-cell > .card > .card-content .descricao {
  width: 100%;

  font-weight: 1000;
  color: white;

  margin-top: 0.8em;
  margin-bottom: -0.3em;
}

#production-card-cell > .card > .card-content .observacao {
  flex: 1 1 auto;
  align-self: flex-end;

  height: 2.8em;
  padding-left: 0.2em;
  padding-right: 0.2em;

  background: transparent;

  font-size: 0.8em;
  font-weight: 500;

  color: white;
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  white-space: pre-wrap;

  border-radius: 0.2em;
}

#production-card-cell > .card > .card-content .observacao.linha-unica {
  height: 1.4em;
  -webkit-line-clamp: 1;
}

#production-card-cell > .card > .card-content .quantidade {
  font-size: 0.8em;

  align-self: flex-end;
}

#production-card-cell > .card > .card-content .selecao {
  display: flex;
  align-items: center;

  min-width: 1em;
  width: 1em;
  min-height: 1em;
  height: 1em;

  background: white;

  color: black;

  border: 1px solid rgb(128, 128, 128);
  border-radius: 0.2em;
}

#production-card-cell > .card > .card {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

#production-card-cell > .card > .card-footer {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;

  margin: 0.1em 0.4em;

  border-radius: 0.2em;

  height: 2em;

  background-color: transparent;
}

#production-card-cell > .card > .card-footer .data-hora {
  text-align: center;
  padding: 0.3em;
  background-color: rgb(230, 230, 230);
  color: black;

  font-size: 0.5em;

  white-space: normal;

  border-radius: 0.2em;
  margin-right: 0.75em;
}

#production-card-cell > .card > .card-footer .color-yellow {
  background-color: yellow;
}

#production-card-cell > .card > .card-footer .color-red {
  background-color: red;
  color: white;
  font-weight: 400;
}

#production-card-cell > .card > .card-footer .estado {
  flex-grow: 1;
  align-content: flex-end;
  text-align: center;

  border-radius: 0.2em;

  width: 100%;
}

#production-card-cell > .card > #image-delivery {
  margin: 0;
  padding: 0 10px;
  font-size: 64px;
  background: none;
  background-color: transparent;
  color: white;
}

#production-card-cell > .card:hover > h1,
#production-card-cell > .card:hover > h2,
#production-card-cell > .card:hover > h3 {
  color: white;
}

#production-card-cell #card-delivery {
  display: inline-flex;
  width: 100%;
}
  
#production-card-cell #card-delivery > p {
  color: white;
  font-size: 32px;
  font-weight: bolder;
}

#production-card-cell > .button-delivery {
  font-size: xx-large;
  font-weight: bolder;
  color: white;
  width: 10%;
}

#production-card-cell .helmet {
  color: inherit;
}

#production-card-cell .delivery-actions-div {
  width: 100%;
  text-align: right;
}

#production-card-cell .delivery-actions-div > button {
  font-size: xx-large;
  font-weight: bolder;
  color: white;
  width: 115px;
  height: 100%; 
}

#production-card-cell .card-container:focus, #production-card-cell .card-container:hover > .card-content > .helmet {
  color: white !important;
}

#production-card-cell .card-container:focus, #production-card-cell .card-container:hover > div > .button-delivery {
  background-color: var(--color-primary);
}

.drag-n-drop {
  display: inline-flex;
  width: 100%;
  font-weight: bolder;
  font-size: x-large;
  color: white;
}

.card.adding-to-delivery {
  background: none;
  background-color: white;
  color: black; 
}

div.selection-area {
  background: none;
  border: 3px dashed lightgray;
}

.uri-container {
  display: none;
}