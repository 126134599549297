.accordion-content {  
  max-height: 0;
  overflow: hidden;
  color: var(--color-text-dark);

  transition: overflow 0.2s ease-in-out;
}

.active + .accordion-content {
  max-height:100%;
  overflow: inherit;

  transition: overflow 0.2s ease-in-out;
}