.toolbar {
  position: sticky;
  top: var(--size-headertop);
  z-index: 12;
  background: var(--cor-de-fundo);
  padding: 1.5px 0;
  margin-top: 5px;
}

/* Espaço no meio da toolbar quando não há botões */
.grid-space {
  font-size: 32.3px;
  white-space:nowrap;
  text-align: center;
  user-select: none;
  background-color: var(--color-toolbar-emptyspace);
  border-radius: var(--default-border-radius-button);
}

.bar-item {
  flex-grow: 1;
}
.bar-item-big {
  flex-grow: 2;
}

.toolbar-content .bar-item,
.toolbar-content .bar-item-big {
  display: flex;
}

.toolbar-content .bar-border {
  width: 100%;
}

/* Borda entre os itens da toolbar */
.bar-border {
  padding: 1.5px;
}

.bar-container {
  display: flex;
  flex-wrap: wrap;
}

.bar-container.justify-content-center {
  justify-content: center;
}

.bar-navegacao {
  display: flex;
  padding: 0em 1em 0.5em 1em;
}

@media screen and (max-width: 600px) {
  .bar-navegacao {
    padding-top: 0.6em;
  }
}

.bar-botoes {
  display: flex;
  flex-grow: 4;
  flex-wrap: wrap;
  padding-bottom: 0.3em;
}

.bar-button-label-text {
  font-weight: 500;
  white-space: nowrap;
}

/* sm */
@media screen and (min-width: 35.5em) {
  .bar-form-button-config-0,
  .bar-form-button-config-1 {
    flex-grow: 7;
  }
  .bar-form-space-config-0 {
    flex-grow: 10;
  }
  .bar-form-space-config-1 {
    flex-grow: 2;
  }
  .bar-form-space-config-2 {
    flex-grow: 17;
  }
}

/* md */
@media screen and (min-width: 48em) {
  .bar-form-button-config-0,
  .bar-form-button-config-1 {
    flex-grow: 6;
  }

  .bar-form-space-config-0 {
    flex-grow: 12;
  }

  .bar-form-space-config-1 {
    flex-grow: 3;
  }
  .bar-form-space-config-2 {
    flex-grow: 18;
  }
}

/* lg */
@media screen and (min-width: 64em) {
  .bar-form-button-config-0 {
    flex-grow: 5;
  }
  
  .bar-form-space-config-0 {
    flex-grow: 14;
  }
  .bar-form-space-config-2 {
    flex-grow: 19;
  }
}
