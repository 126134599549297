:root {
  --cadastro-print-code-padding: 0.5cm;
}

.cadastroCodeDiv {
  align-items: center;
  background: white;
  display: inline-flex;
  flex-flow: column nowrap;
  padding: var(--cadastro-print-code-padding);
}

.cadastroCodeFont {
  display: inline-block;
  font-size: var(--cadastro-print-code-font-size);
}

.cadastroCodeImage, .cadastroCodeImage svg {
  display: inline-block;
  height: var(--cadastro-print-code-height);
}

.cadastroCodeList {
  display: flex;
  flex-flow: row wrap;
}

@media print {
  .cadastroCodeNoPrint {
    display: none;
  }
}