.descricao-dialog {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.descricao-dialog > div {
  font-family: 'Roboto';
  font-size: 1.1em;
  font-weight: 400;
}