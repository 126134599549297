@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  src: local("Montserrat"), url(../fonts/Montserrat/Montserrat-Black.ttf) format("truetype");
}
