#quadro-horarios-form .quadro-horarios-container {
  width: 100%;
  margin-top: 25px;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios > .quadro-horarios-horas-container {
  align-self: flex-end;
  margin-right: 20px;
  position: relative;
  top: 10px;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios > .quadro-horarios-horas-container > .quadro-horarios-horas {
  height: 100%;
  top: inherit;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  margin-right: 12px;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios > .quadro-horarios-horas-container > .quadro-horarios-horas > .quadro-horarios-horas__hora {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  height: 0;
  position: absolute;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container {
  width: calc(100% - 16px);
  display: flex;
  justify-content: center;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-cabecalho {
  align-self: center;
  font-size: 1.1em;
  font-weight: 900;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
  top: 15px;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-intervalos {
  position: absolute;
  width: calc(100% - 8px);
  left: 4px;
  height: 100%;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-intervalos > .dia-semana-horarios__intervalo {
  border-radius: 6px;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--color-secondary);
  z-index: 2;
  border: none;
  overflow: hidden;
  opacity: 0.8;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-intervalos > .dia-semana-horarios__intervalo > .dia-semana-horarios__intervalo__horas {
  color: white;
  margin: 8px 4px;
  line-height: 16px;
  font-size: 17px;
  top: 0;
  left: 0;
  width: 100%;
}

@media screen and (max-width: 840px) {
  #quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-intervalos > .dia-semana-horarios__intervalo > .dia-semana-horarios__intervalo__horas {
    font-size: 15px;
  }
}

@media screen and (max-width: 640px) {
  #quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-intervalos > .dia-semana-horarios__intervalo > .dia-semana-horarios__intervalo__horas {
    font-size: 13px;
  }
}

@media screen and (max-width: 440px) {
  #quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-intervalos > .dia-semana-horarios__intervalo > .dia-semana-horarios__intervalo__horas {
    font-size: 11px;
    margin: 0;
  }
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-grade {
  border: 1.2px solid #c8c8c8;
  border-right: none;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  background: transparent;
  overflow: hidden;
  border-radius: 0px;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-grade.primeiro-dia-semana {
  border-radius: 10px 0 0 10px;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-grade.ultimo-dia-semana {
  border-radius: 0 10px 10px 0;
  border-right: 1.2px solid #c8c8c8;
}

#quadro-horarios-form .quadro-horarios-container > .quadro-horarios .quadro-horarios-dias-container > .quadro-horarios-dias__dia-semana > .dia-semana-horarios > .dia-semana-horarios-grade > .dia-semana-horarios-grade__linha {
  position: absolute;
  width: 100%;
  height: 0;
  left: 0;
  pointer-events: none;
  border-top: 1px solid #0000000d;
}