.cursor-pointer *, .clickable * {
  cursor: pointer;
}

#main-form > .sub-form {
  justify-content: center;
}

/* Formulários de cadastro e filtros */
.sub-form {
  display: grid;

  /* Este padding é adicionado hard-coded no cálculo da máscada dos Tours (não tinha outra maneira) 
    Caso alterar o padding, adicionar também no cálculo do tamanho do node na classe TourPortal.js do Tour, no método calculateNode()
    Caso não modificar, haverá um pequeno redimensionamento do campo quando o Tour foca-lo */
  padding: 10px 0px;
}

.sub-form > * {    
  margin: calc(1px + 0.3vw) 0;
}

.usuario-form, .empresa-form .endereco-form {
  padding: 10px 0px;
}

.usuario-form, .empresa-form .endereco-form > * {
  margin: calc(1px + 0.3vw) 0 !important;
}

.producao-filtro {
  display: flex;
  flex-flow: row wrap;

  padding: 10px 0px;

  margin-left: 0.75em;
  margin-right: 0.75em;
}

.producao-filtro > * {
  margin: 0 0.25em 0.5em 0.25em;
  flex-grow: 1;
  min-width: 16em;
  align-self: center;
}

.producao-filtro .Select-input:focus {
  outline: none !important;
}

.producao-filtro .Select-control .Select-input:focus {
  background: inherit;
}

/* At least one input mechanism of the device 
 includes a pointing device of limited accuracy. */
.scrollTableByDraggingOutside_cadastroList {
  min-height: 100%;
  height: auto;
  overflow-y: auto;
}

.verticalCenterTextAndSVG label {
  display: block;
  white-space: nowrap;
}

/* left element */
.verticalCenterTextAndSVG svg,
.verticalCenterTextAndSVG input,
/* right element */
.verticalCenterTextAndSVG label span {
  vertical-align: middle;
}

.checkBoxLabelHorizontal {
  padding: 5px;
}

/* Para o datetime picker não aparecer em cima do spinner.
 O uso de !important deveria ser evitado, mas o valor padrão é 99999 !important... */
.rdtPicker {
  z-index: 100 !important;
}

:focus { 
  /* outline: 2px solid var(--color-outline); Rever adição apenas navegando com o tab, e não aparecer quando clickado. */
  outline: none;
}

/* way the hell off screen */
.scrollbar-measure {
  width: 100px;
  height: 100px;

  overflow: scroll;
	position: absolute;
  top: -9999px;
}

.dialog-horizontal-button-grid.ancient-chrome-mobile {
  float: right;
}

.dialog-horizontal-button-grid.ancient-chrome-mobile > button {
  width: auto;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.dialog-horizontal-button-grid * {
  border: 2px solid;
}

.dialog-horizontal-button-grid svg {
  border: none;
}

.hidden {
  display: none;
}

/* https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md */
.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  padding: 0;
  text-align: start;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}
