.tela-sign-up {
  text-align: center;
}

.tela-sign-up .button-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr 20px 1fr;
      grid-template: "btn1 btn2" 1fr /
                      1fr  1fr;
  grid-gap: 20px;
  align-items: center;
  justify-items: center;
  max-height: 20px;
}

.tela-sign-up .button-grid .cell {    
  background: var(--color-home-buttonmain);
  border: 2px solid var(--color-home-buttonmain);
  display: flex;
  height: 100%;
  width: 100%;
}

.tela-sign-up .button-grid .cell:first-child {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: btn1;
}

.tela-sign-up .button-grid .cell:last-child {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-area: btn2;
}

.tela-sign-up .button-grid a {
  padding: calc(5px + 0.4vw);
  font-weight: bold;
  font-size: var(--size-home-button-text);
  color: var(--color-home-textsecondary);
  align-self: center;
  vertical-align: center;
  width: 100%;
}

.tela-sign-up.ancient-chrome-mobile > .button-grid {
  display: inline-flex;
  width: 100%;
}

.tela-sign-up.ancient-chrome-mobile > .button-grid > .cell {
  height: 15vh;
  margin: 0 2.5px 10px 2.5px;
}

.tela-sign-up.ancient-chrome-mobile > .button-grid > .cell > a {
  text-align: center;
}

#campos-usuario,
.reCaptcha-user {
  margin: 0 5px 0 8px;
}

#campos-usuario > div > *,
.reCaptcha-user > div > * {
  margin-top: calc(5px + 0.25vh);
}

#campos-usuario > div .endereco-form > *,
.reCaptcha-user > div .endereco-form > * {
  margin-top: calc(5px + 0.25vh);
}

#campos-usuario > .checkbox-form-cell,
.reCaptcha-user > .checkbox-form-cell {
  margin-top: calc(5px + 0.25vh);
}

/* Organização das divs é diferente em empresa usuário (SignUpUserForm.js) */
#campos-usuario > #g-recaptcha, #g-recaptcha {
  margin: calc(10px + 0.25vh) 12px 0 12px;
}

