.ui-seletor {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: auto;

  margin-top: 0.1em;
  margin-bottom: 0.6em;
  font-size: 1em;
}

.ui-seletor-label {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: auto;

  font-size: 1em;
}

/* The container */
.seletor-item {
  position: relative;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  font-family: 'Arial';
  font-weight: 400;
  font-size: 0.9em;

  width: 100%;
  height: auto;

  border: var(--entrada-borda);
  border-radius: var(--entrada-borda-raio);
  padding: 0.5em 0.5em;

  background: var(--cor-da-entrada);
  color: var(--cor-da-entrada-texto);
}

/* Hide the browser's default radio button */
.seletor-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.8em;
  width: 0.8em;
  background: #eee;
  border-radius: 50%;
  
  border: var(--botao-borda);
}

/* On mouse-over, add a grey background color */
.seletor-item:hover input ~ .checkmark {
  background: #ccc;
}

/* When the radio button is checked, add a blue background */
.seletor-item input:checked ~ .checkmark {
  background: var(--cor-principal);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.seletor-item input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.seletor-item .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
  border-radius: 50%;
	background: none;
}
