#card-pedido-producao {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  margin-top: 0;
}

#card-pedido-producao a {
  color: inherit;
  text-decoration: inherit;
}

#card-pedido-producao #tabs {
   display: flex;
   align-self: flex-end;
   margin: 10px 1.5px -5px 0;
}

#card-pedido-producao #tabs .tab-item {
 display: flex;
 flex: 1 1 auto;
}

#card-pedido-producao #tabs .tab-item > div {
  color: #fff;
  display: flex;
}

#card-pedido-producao #tabs .tab-item > div > svg {
  fill: rgb(70, 110, 196);
  right: 100%;
  left: auto;
  -webkit-transform: scale3d(-1,1,1);
  transform: scale3d(-1,1,1);

  left: 100%;
  margin: 0;
  width: 3em;
  height: 100%;
}

#card-pedido-producao #tabs .tab-item > div span {
  background: rgb(70, 110, 196);
  border-radius: 0 15px 0 0;

  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  min-width: 8em;
  line-height: 2em;
  text-align: end;
  padding-right: 1em;
}

#card-pedido-producao > .card {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    border-radius: var(--default-border-radius-card);
    height: 100%;
    margin-top: 0;
}

#card-pedido-producao > .card > .card-content {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
}

#card-pedido-producao > .card > .card-content .linha {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
}

#card-pedido-producao > .card > .card-content .linha.no-grow {
  flex: 0 1 auto;
}

#card-pedido-producao > .card > .card-content .linha.no-wrap {
  flex-wrap: nowrap;
}

#card-pedido-producao > .card > .card-content .linha.alinhada-a-direita {
  justify-content: flex-end;
}

#card-pedido-producao > .card > .card-content .nome-cliente {
  flex-grow: 1;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  height: 1.5em;
  margin-right: 0.5em;
  white-space: nowrap;
}

#card-pedido-producao > .card > .card-content .telefone {
  margin-right: 0.3em;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  height: 1.5em;
  white-space: nowrap;
}

#card-pedido-producao > .card > .card-content .endereco {
  width: 100%;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

#card-pedido-producao > .card > .card-content .origem-forma-pagamento {
  flex: 1 1 auto;
  align-self: flex-end;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  margin-top: 0.3em;
}

#card-pedido-producao > .card > .card-content .horarios {
  align-self: flex-end;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  margin-top: 0.3em;
}

#card-pedido-producao > .card > .card-content .quantidade {
  font-size: 0.8em;
  align-self: flex-end;
}

#card-pedido-producao > .card > .card-content .selecao {
  display: flex;
  align-items: center;
  min-width: 1em;
  width: 1em;
  min-height: 1em;
  height: 1em;
  background: white;
  color: black;
  border: 1px solid rgb(128, 128, 128);
  border-radius: 0.2em;
}

#card-pedido-producao .produtos {
  overflow-y: auto;

  max-height: 8em;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  padding: 10px;
  font-weight: 500;
  background-color: #F0F1F2;
  border-radius: 0.3em;
  margin: 0.5em;
  color: var(--color-text-dark);
}

#card-pedido-producao .produtos > .linha {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

#card-pedido-producao .produtos > .linha.enviado {
  background: var(--color-productionstate-send);
}

#card-pedido-producao .produtos > .linha.aguardando {
  background: var(--color-productionstate-waiting);
}

#card-pedido-producao .produtos > .linha.produzindo {
  background: var(--color-productionstate-producing);
}

#card-pedido-producao .produtos > .linha.pronto {
  background: var(--color-productionstate-produced);
}

#card-pedido-producao .produtos > .linha.entrega {
  background: var(--color-productionstate-left-for-delivery);
}

#card-pedido-producao .produtos > .linha.entregue {
  background: var(--color-productionstate-delivered);
}

#card-pedido-producao .produtos > .linha.encerrado {
  background: var(--color-productionstate-closed);
  color: var(--color-text-light);
}

#card-pedido-producao .produtos > .linha.cancelado {
  background: var(--color-productionstate-cancelled);
  color: var(--color-text-light);
}

#card-pedido-producao .produtos > .linha > * {
  display: flex;
  align-items: center;
  min-height: 2em;
}

#card-pedido-producao .produtos > .linha > .coluna-quantidade {
  justify-content: flex-end;
  width: 10%;
  padding-right: 0.8em;
  white-space: normal;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

#card-pedido-producao .produtos > .linha > .coluna-descricao {
  justify-content: flex-start;
  width: 55%;
  white-space: normal;
  padding-left: 0.8em;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

#card-pedido-producao .produtos > .linha > .coluna-valor {
  justify-content: flex-end;
  width: 25%;
  padding-right: 0.8em;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

#card-pedido-producao .produtos > .linha > .coluna-observacao {
  justify-content: flex-end;
  width: 5%;
  white-space: normal;
  padding-left: 0.4em;
  padding-right: 0.4em;
  min-width: 1em;
}

#card-pedido-producao .totais {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 0.5em;
}

#card-pedido-producao .totais > .linha {
  display: flex;
  color: white;
  font-weight: 500;
  min-height: 1.5em;
}

#card-pedido-producao .totais > .linha .total-itens {
  width: 30%;
  margin-left: 1em;
}

#card-pedido-producao .totais > .linha .total-itens-valor {
  flex-grow: 1;
  width: 25%;
  margin-right: 1em;
  text-align: right;
}

#card-pedido-producao .totais > .linha .tele {
  width: 20%;
  margin-left: 1em;
}

#card-pedido-producao .totais > .linha .tele-valor {
  flex-grow: 1;
  width: 25%;
  text-align: right;
}

#card-pedido-producao .totais > .linha .cupom {
  width: 20%;
  margin-left: 1em;
}

#card-pedido-producao .totais > .linha .cupom-valor {
  flex-grow: 1;
  width: 25%;
  margin-right: 1em;
  text-align: right;
}

#card-pedido-producao .totais > .linha .total-pedido {
  width: 30%;
  font-weight: 900;
}

#card-pedido-producao .totais > .linha .total-pedido-valor {
  flex-grow: 1;
  width: 25%;
  margin-right: 1em;
  text-align: right;
  font-weight: 900;
}

#card-pedido-producao .totais > .linha .troco {
  width: 30%;
}

#card-pedido-producao .totais > .linha .troco-valor {
  flex-grow: 1;
  width: 25%;
  margin-right: 1em;
  text-align: right;
}

#card-pedido-producao .rodape {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
}

#card-pedido-producao .rodape .observacao-pedido {
  flex-grow: 1;
  padding: 0.2em;

  height: 2.8em;
  padding-left: 1em;
  padding-right: 1em;
  background: transparent;
  font-size: 0.8em;
  font-weight: 500;
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  border-radius: 0.2em;
}

#card-pedido-producao .rodape .estado-pedido {
  align-self: flex-end;
  margin-right: 0.3em;
  padding: 0.2em;
  border-radius: 0.3em;
  color: var(--color-text-dark);
}