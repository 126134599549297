#barra-navegacao {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;

  width: 100%;
  height: var(--barra-navegacao-altura);
  
  padding: var(--barra-navegacao-padding-vertical) 0;
  
  border-top: var(--borda);

  background: var(--cor-da-barra-navegacao);
  
  color: var(--cor-da-barra-navegacao-texto);

  z-index: 9;
}

#barra-navegacao > .column-left,
#barra-navegacao .column-right {
  justify-content: center;
  max-width: 8em;
}

#barra-navegacao > .column-left,
#barra-navegacao > .column-center,
#barra-navegacao > .column-right {
  width: 100%;

  padding: 0;
}
#barra-navegacao > .column-left > .ui-botao,
#barra-navegacao > .column-center > .ui-botao,
#barra-navegacao > .column-right > .ui-botao {
  height: 1.8em;
}

#barra-navegacao .column-center {
  font-family: 'Roboto';
  font-size: 1.2em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
