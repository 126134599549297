.help {
  padding: 5px;
  margin: 11.5px 0;
  font-weight: 400;
  background: var(--color-help-background);
  color: var(--color-help-text);
  text-align: center;
}

.help p {
  margin: 12px 12px;
}

.help p,
.help li {
  text-align: justify;
}

.help span:not(.icon) {
  padding: 5px;
}

.help button {
  background: var(--color-help-button);
  font-weight: bold;
  padding: 3px;
  margin: 3px;
  width: auto;
  height: 30px;
}

.help button svg {
  height: 100%;
  font-size: 22px;
}