#cadastro-endereco-tela {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  max-width: 50em;
}

#cadastro-endereco-tela > #conteudo input {
  font-family: Arial;
  height: 1.2em;
}

#cadastro-endereco-tela > #conteudo > .campo-duplo {
  display: flex;
  align-items: flex-end;

  width: 100%;
}

#cadastro-endereco-tela > #conteudo > .campo-duplo > .completo {
  width: 100%;
}

#cadastro-endereco-tela > #conteudo > .campo-duplo > .igual {
  width: 50%;
}

#cadastro-endereco-tela > #conteudo > .campo-duplo > .menor {
  width: 25%;
}

#cadastro-endereco-tela > #conteudo > .campo-duplo > .maior {
  width: 75%;
}

#cadastro-endereco-tela > #conteudo > .campo-duplo > .left {
  margin-right: 0.3em;
}

#cadastro-endereco-tela > #conteudo > .campo-duplo > .right {
  margin-left: 0.3em;
}