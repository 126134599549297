button, a.button {
  border-radius: var(--default-border-radius-button);
}

button {
  width: 100%;
  border: none;
  text-decoration: none;
  display: inline-block;

  user-select: none;
}

button:enabled, button:enabled label {
  cursor: pointer;
}

button:enabled:hover {
  filter: var(--hover-filter);
}

button:enabled:active {    
  filter: var(--active-filter);
}

/* TOOLBAR
 Botões principais */
.bar-button-primary {
  padding: 5px;
  text-align: center;
  font-size: 25px;
  background: var(--color-toolbar-buttonmain);
  color: var(--color-background);
  min-width: 40px;
}

/* Botão para definir número de registros por tela */
.bar-button-primary-more {
  font-size: 22.5px;
}

.bar-button-primary:hover:not(:disabled), .bar-button-primary:focus:not(:disabled) {
  background: var(--color-headertop-background);
}

/* Botões secundários */
.bar-button {
  padding: 5px;
  text-align: center;
  font-size: 25px;
  background: var(--color-toolbar-buttonsecondary);
  color: var(--color-text-dark);
  min-width: 40px;
}

/* Botão para definir número de registros por tela */
.bar-button-more {
  font-size: 22.5px;
}

.bar-button-primary-white {
  padding: 5px;
  text-align: center;
  font-size: 25px;
  background: var(--color-toolbar-buttonmain);
  color: var(--color-background);
  min-width: 40px;
}

/* Botão para definir número de registros por tela */
.bar-button-primary-white-more {
  font-size: 22.5px;
}

.bar-button-primary-white:hover:not(:disabled), .bar-button-primary-white:focus:not(:disabled) {
  background: var(--color-headertop-background);
}

.bar-button-primary-white.pressionado.blue-button {
  background: none !important;
  color: var(--color-text-link) !important;
  border: 2px solid !important;
  padding: 3px !important;
}

.bar-button-primary-white.pressionado.blue-button:hover, .bar-button-primary-white.pressionado.blue-button:focus {
  background: none !important;
  color: var(--color-text-link) !important;
  border: 2px solid !important;
  padding: 3px !important;
}

.bar-button-primary-white:disabled {
  color: white !important;
}

.bar-button-primary-white-selecao {
  display: flex;
  align-items: center;
  align-self: center;

  cursor: pointer;

  min-width: 1.8em;
  width: 1.8em;
  min-height: 1.8em;
  height: 1.8em;

  background: white;
  color: black;

  border: 1px solid rgb(128, 128, 128);
  border-radius: 0.2em;

  margin: 0 0.5em 0 0;
}

@media screen and (max-width: 1080px) {
  .bar-button-label-text {
      display: none;
  }
}

.warning-button {
  padding: 5px;
  text-align: center;
  font-size: 25px;
  background: var(--color-toolbar-buttonsecondary);
  color: var(--color-text-dark);
  min-width: 40px;
  background-color: rgb(250, 60, 60);
  color: white;
}

/* Botão para definir número de registros por tela */
.warning-button-more {
  font-size: 22.5px;
}

.pure-g.option-buttons {
  flex-flow: row nowrap;
}

/* Botões de opção da tabela */
.opt-button {
  margin: 0 5px;
  padding: 2px;
  padding-bottom: 5px;
  text-align: center;
  font-size: 20px;
  user-select: none;
  background-color: var(--color-toolbar-buttonsecondary);
  color: var(--color-text-dark);
}

.bar-button-primary:disabled,
.bar-button:disabled,
.opt-button:disabled,
.tableHeaderControls button:disabled {
  opacity: 0.2;
}

.opt-button:hover, .opt-button:focus {
  background-color: var(--color-gray-button-focused);
}

/* DIALOG
 Configura botões a aparecer na BarraAcoesForm */
.bar-form-config {
  flex-grow: 6;
}

.bar-form-button-config-0, .bar-form-button-config-1,
.bar-form-space-config-0 {
  flex-grow: 8;
}

.bar-form-space-config-1 {
  flex-grow: 1;
}

.bar-form-space-config-2 {
  flex-grow: 16;
}

.button-gradient {
  background-color: var(--color-card-background);
}

.blue-button:focus:not(:disabled),
.gray-button:focus:not(:disabled),
.light-button:focus:not(:disabled),
.dark-button:focus:not(:disabled),
.blue-button:hover:not(:disabled),
.gray-button:hover:not(:disabled),
.light-button:hover:not(:disabled),
.dark-button:hover:not(:disabled) {
  background: none;
}

/* Blue Button */
.blue-button:focus:not(:disabled),
.blue-button:hover:not(:disabled) {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
  color: #fff !important;
}

/* Gray Button */
.gray-button:focus:not(:disabled),
.gray-button:hover:not(:disabled),
.gray-button > button:focus:not(:disabled),
.gray-button:hover > button:not(:disabled),
.gray-button > button:focus:not(:disabled) ~ div {
  background-color: var(--color-gray-button-focused) !important;
  border-color: var(--color-gray-button-focused) !important;
  color: #fff !important;
}

.gray-button > button:focus:not(:disabled) > h2,
.gray-button > button:hover:not(:disabled) > h2,
.gray-button > button:focus:not(:disabled) ~ div > h3,
.gray-button:hover:not(:disabled) > div > h3 {
  color: #fff !important;
}

/* Light Button */
.light-button:focus:not(:disabled),
.light-button:hover:not(:disabled),
.light-button > button:focus:not(:disabled),
.light-button:hover > button:not(:disabled),
.light-button > button:focus:not(:disabled) ~ div {
  background-color: var(--color-light-button-focused) !important;
  border-color: var(--color-light-button-focused) !important;
  color: black !important;
}

.light-button > button:focus:not(:disabled) > h2,
.light-button > button:hover:not(:disabled) > h2,
.light-button > button:focus:not(:disabled) ~ div > h3,
.light-button:hover:not(:disabled) > div > h3 {
  color: black !important;
}


/* Dark Button */
.dark-button {
  background-color: var(--color-dark-button) !important;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 2px;
  width: 20px;
  height: auto;
  font-size: calc(1.2rem + 0.5vw);
}

.dark-button:focus:not(:disabled),
.dark-button:hover:not(:disabled) {
  background-color: transparent !important;
  color: var(--color-dark-button) !important;
  border: 1px solid var(--color-dark-button) !important;
  border-radius: 2px;
}

.dark-button.decrementa {
  float: left;
}

.dark-button.incrementa {
  float: right;
}

.del-button:disabled {
  color: gray !important;
}

.del-button.ready {
  background-color: var(--color-toolbar-buttonmain) !important;
}

.rounded-button {
  width: 15px;
  height: 15px;
  background-color: lightgray;
  border-radius: 100%;
}

.discrete-button {
  background-color: var(--color-background);
}

.danger-button {
  color: #fff;
  background-color: var(--color-danger);
}

.danger-button:focus,
.danger-button:hover {
  background-color: var(--color-danger-focused);
}

.danger-button:disabled {
  background-color: var(--color-danger-disabled);
}

button:disabled {
  color: gray !important;
}

.delete-button:focus,
.delete-button:hover {
  background-color: var(--color-gray-button-focused);
  color: var(--color-text-light);
}

.button-white {
  width: fit-content;

  background: none;

  border: 2px solid var(--color-secondary);
  min-width: 6em;
  
  color: var(--color-secondary);
  font-size: 1.25em;
  font-weight: 400;

  margin: 2px;
  padding: 4px;
}