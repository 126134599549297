.table.itens-venda-table.ancient-chrome-mobile {
  padding: 4px;
  display: block;
}

.table.itens-venda-table.ancient-chrome-mobile .column,
.table.itens-venda-table.ancient-chrome-mobile .row {
  display: inline-flex;
  min-width: fit-content;
  width: 100%;
  padding: 0;
}

.table.itens-venda-table.ancient-chrome-mobile .row {
  flex-direction: row;
}

.table.itens-venda-table.ancient-chrome-mobile .left {
  float: left;
}

.table.itens-venda-table.ancient-chrome-mobile .right {
  float: right;
}

.table.itens-venda-table.ancient-chrome-mobile button {
  max-width: 30px;
} 

.table.itens-venda-table.ancient-chrome-mobile .ButtonAlign,
.table.itens-venda-table.ancient-chrome-mobile .NumberAlign,
.table.itens-venda-table.ancient-chrome-mobile .TextAlign,
.table.itens-venda-table.ancient-chrome-mobile .table-cell {
  display: block;
  min-width: fit-content;
  width: 100%;
  text-align: left;
  font-size: calc(7px + 2vw);
}

.table.itens-venda-table.ancient-chrome-mobile .blank-space {
  max-width: 40px;
  width: 100%;
}

.table.itens-venda-table.ancient-chrome-mobile .table-cell {
  background-color: var(--color-table-row-background);
}

.table.itens-venda-table.ancient-chrome-mobile > .table-items {
  display: inline-flex;
  width: 100%;
  text-align: left;
}

.table.itens-venda-table.ancient-chrome-mobile > .table-items > .column {
  display: inline-block;
}

.table.itens-venda-table.ancient-chrome-mobile > .table-items > .column.left {
  width: 35%;
}

.table.itens-venda-table.ancient-chrome-mobile > .table-items .row .obs.ButtonAlign {
  text-align: right;
}

.table.itens-venda-table.ancient-chrome-mobile > .table-items .row .obs.ButtonAlign.header {
  padding-right: 8px;
}

.table.itens-venda-table.ancient-chrome-mobile .hide-when-small {
  display: inline-flex;
}

.table.itens-venda-table.ancient-chrome-mobile .display-when-small {
  display: none;
}

.table.itens-venda-table.ancient-chrome-mobile .display-when-small.row {
  margin-top: 2px;
}

@media screen and (max-width: 21.5em) {
  .table.itens-venda-table.ancient-chrome-mobile .hide-when-small {
    display: none;
  }

  .table.itens-venda-table.ancient-chrome-mobile .display-when-small {
    display: inline-flex;
  }
}

.table.itens-venda-table.ancient-chrome-mobile .del,
.table.itens-venda-table.ancient-chrome-mobile .obs {
  min-width: 30px;
  max-width: 30px;
}

.table.itens-venda-table.ancient-chrome-mobile .NumberAlign,
.table.itens-venda-table.ancient-chrome-mobile .ButtonAlign,
.table.itens-venda-table.ancient-chrome-mobile .TextAlign {
  display: block;
  padding: 0 2px;
}

.table.itens-venda-table.ancient-chrome-mobile .TextAlign {
  text-align: left;
}

.table.itens-venda-table.ancient-chrome-mobile .NumberAlign,
.table.itens-venda-table.ancient-chrome-mobile .ButtonAlign {
  text-align: right;
}
