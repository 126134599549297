#cadastro-setup > .flex {
  flex-wrap: wrap;
}

#cadastro-setup > .flex > div {
  flex-grow: 1;
  min-width: 325px;
}

#cadastro-setup > .flex > div:first-child {
  margin-right: 7.5px;
}

#cadastro-setup > .flex > div:last-child {
  margin-left: 7.5px;
}

@media screen and (max-width: 678px) {
  #cadastro-setup > .flex > div {
    margin: calc(1px + 0.3vw) 0;
  }

  #cadastro-setup > .flex > div:first-child {
    margin-right: 0;
  }

  #cadastro-setup > .flex > div:last-child {
    margin-left: 0;
  }
}