.breadCrumbs {
  font-size: calc(0.5em + 0.5vw);
  margin: 0;
  padding: 15px 10px 5px 15px;
  color: var(--color-text-dark);
}
  
.breadCrumbs > h1 {
  margin: 0;
  margin-top: -0.1em;
  font-size: 2em;
  font-weight: lighter;
}

.breadCrumbs > h2 {
  padding: 0;
  margin-top: 0;
  color: var(--color-breadcrumbs-text);
  font-weight: lighter;
  text-align: left;
}

.breadCrumbs .header-title {
  font-size: 2em;
  display: flex;
  justify-content: space-between;
}