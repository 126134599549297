.DrawerToggle {
    width: 30px;
    height: 30px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 40px;
    text-align: center;
    grid-column: 1;
    -ms-grid-column: 1;
    grid-row: 1;
    -ms-grid-row: 1;
    -ms-grid-row-align: center;
    grid-area: 1 / 1 / 2 / 2;
    margin: 0;
    padding: 0;
}

.DrawerToggle div {
    margin: 0;
    padding: 0;
    width: auto;
    height: 3px;
}