:root {
    --botao-cor-de-fundo: var(--global-cor-principal);
    --botao-cor-do-texto: var(--color-text-light);
    --botao-borda: var(--global-borda);
    --botao-raio-da-borda: var(--global-raio-da-borda);
}

button.ui-botao-interno,
a.ui-botao-interno {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 2em;
    
    border: var(--botao-borda);
    border-radius: var(--botao-raio-da-borda);
    
    background: var(--botao-cor-de-fundo);
    
    color: var(--botao-cor-do-texto);
    font-weight: 500;
    
    border: none;
    cursor: none;
}

button.ui-botao-interno.botao-white-n-blue,
a.ui-botao-interno.botao-white-n-blue {
    width: auto;
    height: auto;
    background: white;
    color: var(--color-secondary);
    border: var(--default-border-button);
    border-radius: var(--default-border-radius-button);
    padding: 0.5em;
    font-weight: 700;
    cursor: pointer;
}
