#campos-empresa, #campos-endereco, #campos-contato, #campos-parametros {
  margin: 0 5px 0 8px;
}

#campos-empresa, #campos-endereco, #campos-contato, #campos-parametros > * {
  margin-top: calc(5px + 0.25vh);
}

#campos-empresa, #campos-endereco, #campos-contato, #campos-parametros .endereco-form > * {
  margin-top: calc(5px + 0.25vh);
}