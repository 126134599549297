/*
 * TODO descobrir como fazer ocupar toda a área restante.
 * Enquanto isto, feito para ter uma altura para cada altura mínima do dispositivo.
 * Valores de altura definidos usando os valores da série E12, elevados à 1,5 e multiplicados por 50.
 * Ou seja, (E12 ^ 1.5) * 50. Série E12: 1,0; 1,2; 1,5; 1,8; 2,2; 2,7; 3,3; 3,9; 4,7; 5,6; 6,8; 8,2.
 * Em seguida, os valores de altura mínima foram verificados como a menor altura em que o elemento cabia,
 * com largura em 1920. Em larguras menores, há mais folga.
 */
/* TODO compatibilidade IE não testada */
 .videoElement {
  text-align: center;
  width: 100%;
  height: calc(100vh - 249px);
}

@media only screen and (max-width: 360px) {
  .videoElement {
    text-align: center;
    height: calc(100vh - 284px);
  }
}