.ui-botao-principal {
  position: relative;

  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 6em;
  height: 2.5em;
  
  border: var(--borda);
  
  box-shadow: var(--borda-cor) 0.05em 0.05em;

  background: var(--cor-botao-de-acao);
  color: var(--cor-botao-de-acao-texto);

  font-family: 'Roboto';
  font-weight: 400;
}

.ui-botao-principal:disabled {
  display: none;
}

.ui-botao-principal > svg {
  width: 1.8em;
  height: 1.8em;
}