.botao-tipo-origem-venda-pedidos-tela {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;

  justify-content: center;

  min-width: fit-content;
  height: auto;

  font-size: 1.25em;
  font-weight: 400;

  margin: 0.2em 0.1em;

  padding: 0.25em;

  background: none;
  color: var(--color-text-link);
  border: 2px solid;
}

.botao-tipo-origem-venda-pedidos-tela.origem-venda-selecionada {
  background: var(--color-secondary);
  color: var(--color-text-light);
  border: none;
}

.botao-tipo-origem-venda-pedidos-tela:disabled {
  background: rgb(118, 118, 118);
  color: white;
}

.botao-tipo-origem-venda-pedidos-tela.blink_me {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: white;
}

.nenhuma-origem-venda-tela-pedidos {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  text-align: center;

  font-size: 1.25em;
  font-weight: 500;

  height: 100%;
}

@media screen and (min-height:400px) {
  .nenhuma-origem-venda-tela-pedidos {
    min-height: 14em;
  }
}

@media screen and (min-height:600px) {
  .nenhuma-origem-venda-tela-pedidos {
    min-height: 22em;
  }
}

@media screen and (min-height:800px) {
  .nenhuma-origem-venda-tela-pedidos {
    min-height: 30em;
  }
}