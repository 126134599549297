div.F3DialogComponent {
  text-align: center;
}
  
div.F3DialogComponent > div {
  margin: 0 10px 7px 0;
}

div.F3DialogComponent > div > * {
  width: auto;
}

div.F3DialogComponent > .f3-dialog-content {
  margin-bottom: 50px;
}

div.F3DialogComponent > .f3-dialog-content p {
  font-size: medium;
}