.tela-integration {
  font-size: 1rem;
}

.tela-integration h2 {
  text-align: center;
  color: var(--color-text-dark);
}

.tela-integration .tokenInputBox {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
}

.tela-integration .tokenInputBox button {
  width: 38px;
  height: 38px;
  margin: 0 5px;
  text-align: center;
  line-height: 50%; /* Alinhar ícone ao centro */
  background-color: var(--color-toolbar-buttonsecondary);
}

.tela-integration .tokenInputBox div { /* Necessário pois o inputCustomizado é envolto por divs */
  flex-grow: 1;
}

.tela-integration .downloadButtonsBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tela-integration .downloadButtonsBox a {
  margin: 2.5px;
  flex-grow: 1;
  height: 35px;
}

.tela-integration .downloadButtonsBox button {
  height: 100%;
  background-color: var(--color-toolbar-buttonsecondary);
}

.tela-integration .version {
  text-align: center;
}