.abas-template {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  height: 100%;
}

.abas-template > .abas-template-conteudo {
  height: 80%;
}

.abas-template > .botoes-aba-template {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;

  z-index: 15;

  background: white;

  position: sticky;
  top: var(--size-headertop);
  padding-top: 0.25em;

  width: 100%;
}

.abas-template > .botoes-aba-template > button {
  display: flex;
  flex-flow: row wrap;
  flex: 0 1 auto;
  align-items: center;
  white-space: nowrap;
  justify-content: center;

  width: 7.5em;
  height: 2em;

  border-radius: 0;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;

  margin: 0;

  font-size: 1.4em;
  font-weight: 500;
}

.abas-template > .botoes-aba-template > button:disabled {
  background: var(--color-primary) !important;
  color: white !important;
}

@media screen and (max-width: 768px) {
  .botoes-aba-template > button {
    flex-grow: 1 !important;
  }
}