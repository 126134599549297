#feriados-form {
  font-weight: 500;
  margin: 25px 15px;
}

#feriados-form > .meses > .meses__mes {
  margin-top: 15px;
}

#feriados-form > .meses > .meses__mes > .meses__mes__nome {
  font-size: 24px;
  font-weight: 700;
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado {
  margin-top: 5px;
  border-radius: 6px;
  border: 1px solid #8080803b;
  padding: 0.4em 0;

  cursor: pointer;
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado:hover {
  background-color: #eeeeee;
  filter: var(--hover-filter);
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container {
  display: flex;
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container > button {
  width: 35px;
  height: 30px;
  margin: auto 0.5em auto 1em;
  background-color: #e11414;
  color: white;
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container > .feriado-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 1em 0 0.5em;
  width: 100%;
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container > .feriado-info > .feriado-data {
  display: flex;
  text-align: center;
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container > .feriado-info > .feriado-data > .data-nome {
  margin: auto;
  font-weight: 600;
}

#feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container > .feriado-info > .feriado-horarios {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
}

@media screen and (max-width: 500px) {
  #feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container > .feriado-info > .feriado-data {
    width: 100%;
  }

  #feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container > .feriado-info > .feriado-horarios {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  #feriados-form > .meses > .meses__mes > .meses__mes__feriado > .feriado-container {
    font-size: 0.95em;
  }
}