/* Hide default HTML checkbox */
.switch {
  margin: 2px 0;

  /* The switch - the box around the slider */
  position: relative;
  display: inline-block;
  width: 30px;
  min-width: 30px;
  height: 17px;
}

/* The slider */
.switch > .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch > .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch > input[type=checkbox] {
  display: none;
}

.switch > input[type=checkbox]:checked + .slider {
  background-color: var(--color-gray-button-focused);
}

.switch > input[type=checkbox]:focus + .slider {
  box-shadow: 0 0 1px var(--color-gray-button-focused);
}

.switch > input[type=checkbox]:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.checkbox-form-cell {
  display: flex;
}

.checkbox-form-cell > div {
  width: auto;
  padding-left: 5px;
}

.switch-label {
  margin: 0;
}