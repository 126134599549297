#dev-page {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  width: 100%;

  overflow-x: hidden;
}

#dev-page > p,
#dev-page > ul > li {
  max-width: 50em;
}