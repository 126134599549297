.snackbar-template {
  position: fixed;
  bottom: calc(0.5em + var(--barra-navegacao-altura) + var(--barra-navegacao-padding-vertical)*2);
  right: 0.5em;

  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  background: rgb(1, 153, 1);
  color: var(--cor-principal-texto);

  min-width: calc(150px - 0.5em*2);
  max-width: 200px;
  min-height: 2.1em;

  padding: 0.5em 0.5em;

  /* border: 1px solid var(--borda-cor); */
  border-radius: 0.3em;

  transition: display 0s 2s;

  z-index: 9;
}
