.ancient-chrome-mobile > .flow-buttons .left {
  float: left;
}

.ancient-chrome-mobile > .flow-buttons .right {
  float: right;
}

.ancient-chrome-mobile > .flow-buttons .row {
  width: 100%;
  display: inline-flex;
  margin: 0;
}

.ancient-chrome-mobile > .flow-buttons .row > .state-button {
  padding: 1.5%;
  font-size: auto;
}

.ancient-chrome-mobile > .flow-buttons .row,
.ancient-chrome-mobile > .flow-buttons .column {
  padding: 0;
  border: none;
}

.ancient-chrome-mobile > .flow-buttons .row .column {
  width: 50%;
}