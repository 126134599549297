.tamanho-card {
  margin: 0;

  border: none;
  border-radius: 0 !important;

  background: var(--cor-do-card);
  color: var(--cor-do-card-texto);
  
  margin: 0 !important;
}

.tamanho-card > div {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  width: 100%;
  height: auto;

  padding: 0.1em 0;
}

.tamanho-card > div > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  margin: 0.7em 1em;
}

.tamanho-card > div .identificacao-tamanho {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

.tamanho-card > div .identificacao-tamanho > .info {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  height: fit-content;
  margin: 0 0.5em 0 1em;
}

.tamanho-card > div .identificacao-tamanho > .info > div {
  padding: 0;
}

.tamanho-card > div .identificacao-tamanho > .info > .nome {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;

  min-width: 0;
}

.tamanho-card > div .identificacao-tamanho > .info > .nome > .ui-texto {
  overflow-wrap: break-word;

  font-size: 1em;
  font-weight: 500;
  text-align: center;

  min-width: 0;
}

.tamanho-card > div .identificacao-tamanho > .painel-e-preco {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  margin: 0 1em 0 0.5em;
}

.tamanho-card > div .identificacao-tamanho > .painel-e-preco > .controle-quantidade {
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
}

.tamanho-card > div .identificacao-tamanho > .painel-e-preco > .controle-quantidade > .ui-botao {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;

  min-width: 1.2em;
  padding: 0.3em;
  background: var(--cor-principal);
  border: none;

  font-weight: 400;
  font-family: 'Arial';
}

.tamanho-card > div > .linha-separadora {
  margin: 0;
  width: auto;
}
