#barra-logo-empresa {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  z-index: 8;

  background: var(--cor-de-fundo);
  border-radius: 50%;
  color: var(--cor-texto);

  width: 100%;
  max-width: 50em;
  min-height: 50px;
  max-height: 300px;
}

#barra-logo-empresa > .ui-imagem {
  width: 100%;
  height: 100%;
  object-fit: contain;
}