.carrinho-card {
  height: 16em;
  min-height: fit-content;

  background: var(--cor-terciaria);
  color: var(--cor-terciaria-texto);

  margin-bottom: 0.7em !important;
}

.carrinho-card .descricao {
  display: flex !important;
  flex: 1 0 auto !important;
  flex-flow: column nowrap !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;

  margin: 0 0.5em !important;

  width: calc(100% - 1em) !important;
  height: auto !important;
  max-height: 6em !important;
}

.carrinho-card > div {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;

  width: 100%;
  height: auto;
  padding: 0;
}

.carrinho-card > div > div {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;

  margin: 0.3em 0.5em;
}

.carrinho-card > div > .identificacao-produto {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
}

.carrinho-card > div > .identificacao-produto > .info {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.carrinho-card > div > .identificacao-produto > .info > div {
  padding: 0;
}

.carrinho-card > div > .identificacao-produto > .info > .nome {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;

  min-width: 0;
}

.carrinho-card > div > .identificacao-produto > .info > .nome > .ui-texto {
  overflow-wrap: break-word;
  
  font-size: 1em;
  text-align: left;
  
  max-height: auto;
  min-width: 0;
}

.carrinho-card > div > .painel-produto {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.carrinho-card > div > .painel-produto > .preco {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
}

.carrinho-card > div > .painel-produto > .preco > .com-desconto {
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 0.9em;
  font-weight: 500;
}

.carrinho-card > div > .painel-produto > .botoes-dialog {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;
}

.carrinho-card > div > .painel-produto > .botoes-dialog > div {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 0.7em;
}

.carrinho-card > div > .painel-produto > .botoes-dialog > div > .ui-botao {
  width: auto;
  height: auto;

  border: none;
  background: transparent;
  color: black;
  margin-left: 0.8em;
}

.carrinho-card > div > .painel-produto > .botoes-dialog > div > .ui-botao > * {
  width: 2em;
  height: 2em;
}

.carrinho-card > div > .painel-produto > .controle-quantidade {
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 0.5em;
}

.carrinho-card > div > .painel-produto > .controle-quantidade > .ui-botao,
.carrinho-card > div > .painel-produto > .controle-quantidade > .quantidade {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column wrap;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;
  min-width: 1.5em;

  font-weight: 400;
  font-family: 'Arial';
}

.carrinho-card > div > .painel-produto > .controle-quantidade > .ui-botao {
  min-width: 1.2em;

  padding: 0.25em;

  background: var(--cor-principal);

  border: none;
}

.carrinho-card > div > .painel-produto > .controle-quantidade > .ui-botao.diminuir-quantidade {
  border-radius: 0.3em 0 0 0.3em;
}

.carrinho-card > div > .painel-produto > .controle-quantidade > .ui-botao.aumentar-quantidade {
  border-radius: 0 0.3em 0.3em 0;
}

.carrinho-card > div > .painel-produto > .controle-quantidade > .ui-botao:disabled {
  display: flex;
  color: var(--botao-cor-texto-desabilitado);
}

.carrinho-card > div > .painel-produto > .controle-quantidade > .quantidade {
  width: 2em;
  max-height: auto;
  padding: 0.25em 0;
  margin: 0;

  border: none;
  border-radius: 0;
  
  background: var(--cor-da-entrada);
  
  color: var(--cor-da-entrada-texto);
  text-align: center;
}

.carrinho-card > div > .linha-separadora {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;

  margin: 0;

  width: 100%;
  height: 1px;

  background: rgb(128, 128, 128);

  font-weight: lighter;
}

.carrinho-card > div > .painel-carrinho {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  
  font-size: 1em;
  margin-top: 0.5em;
}

.carrinho-card > div > .painel-carrinho > .total-item {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  background-color: transparent;
  border: none;
  
  color: var(--cor-link);
}

.carrinho-card > div > .painel-carrinho > .preco-final {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  white-space: nowrap;
}