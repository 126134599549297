.produtos-com-tamanho-tela {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  max-width: 50em;
  height: auto;
  margin-bottom: 0.7em;

  font-family: 'Roboto';
}

.produtos-com-tamanho-tela > .grupo-produto-header {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 0.6em;
  position: sticky;
  top: 0;
  background-color: var(--cor-de-fundo);
  z-index: 2;
}

.produtos-com-tamanho-tela > .grupo-produto-header > .grupo-produto-titulo {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  height: 100%;

  padding: 0.3em 0 0.2em 0;

  font-size: 1.1em;
  font-weight: 500;
}

.produtos-com-tamanho-tela > .grupo-produto-header > .grupo-produto-quantidades {
  font-size: 0.8em;
  padding-bottom: 0.5em;
 }

.produtos-com-tamanho-tela #barra-de-busca > .ui-entrada {
  font-size: 1em;
}

.produtos-com-tamanho-tela > .produto-card .painel-produto {
  font-size: 0.95em;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;

  font-size: 1.1em;

  width: 100%;
  position: sticky;
  bottom: 0;

  align-items: center;
  justify-content: center;

  background-color: var(--cor-de-fundo);
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > label {
  text-align: center;
  font-size: 0.9em;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;

  margin: 0.5em;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > div > .ui-botao,
.produtos-com-tamanho-tela.unidade .quantidade-geral > div > .ui-entrada {
  font-family: 'Arial';
  font-weight: 400;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > div > .ui-botao {
  width: 1.5em;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > div > .ui-botao.diminuir-quantidade {
  border-radius: 0.3em 0 0 0.3em;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > div > .ui-botao.aumentar-quantidade {
  border-radius: 0 0.3em 0.3em 0;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > div > * {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
}

.produtos-com-tamanho-tela.unidade .quantidade-geral > div > .quantidade {
  width: 2em;

  padding: 0.25em 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: var(--cor-de-fundo);
  color: var(--cor-texto);
  text-align: center;
}