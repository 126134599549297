.tela-home-externa {
  text-align: center;

  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.tela-home-externa .intro {
  text-align: justify;
  padding: 5px;
  margin-top: 0;
}

.tela-home-externa .button-grid {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  padding: 10px 12vw;
}

.tela-home-externa .button-grid > * {
  margin: 10px;
}

.tela-home-externa .button-grid > .reader {
  width: calc(100% - 10px);
}

.tela-home-externa .button-grid > .row-sigin-signup {
  width: 100%;

  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}

.tela-home-externa .button-grid > .row-sigin-signup > .login {
  margin-right: 10px;
}

.tela-home-externa .button-grid > .row-sigin-signup > .siginup {
  margin-left: 10px;
}

.tela-home-externa .button-grid > .row-sigin-signup > * {
  width: calc(100% - 10px);
  padding: 7px 10px;
}

.tela-home-externa .button-grid a {
  padding: calc(5px + 0.025vw);
  font-weight: bold;
  font-size: var(--size-home-button-text);
  border: 2px solid var(--color-home-buttonmain);
}

.tela-home-externa .button-grid a.login,
.tela-home-externa .button-grid a.reader {
  text-decoration: none;
  color: var(--color-home-textmain);
}

.tela-home-externa .button-grid a.sign-up {
  background: var(--color-home-buttonmain);
  color: var(--color-home-textsecondary);
}

.tela-home-externa .button-grid.ancient-chrome-mobile {
  display: block;
  width: 75%;
  text-align: center;
}

.tela-home-externa .button-grid.ancient-chrome-mobile .row {
  width: 100%;
  display: inline-flex;
  margin: 0;
  padding: 0;
}

.tela-home-externa .button-grid.ancient-chrome-mobile .column {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
}

.tela-home-externa .button-grid.ancient-chrome-mobile a {
  margin: 7.75px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .tela-home-externa .button-grid.ancient-chrome-mobile a {
    font-size: calc(18px - 0.5vw);
  }
}

.tela-home-externa .arrow-button {
  background-color: transparent;
  color: var(--color-home-buttonmain);
  width: 100%;
  max-width: 220px;
  height: 40px;
}

.tela-home-externa .arrow-button > * {
  width: 28px;
  height: 28px;
}

.tela-home-externa .arrow-button :hover,
.tela-home-externa .arrow-button :focus {
  background-color: var(--color-home-buttonmain);
  color: white;
}

.tela-home-externa .mobi-advice {
  background-color: black;
}

.tela-home-externa .mobi-advice,
.tela-home-externa .mobi-advice > * {
  color: yellow;
}

.tela-home-externa .supported-companies {
  text-align: left;
}

.tela-home-externa .welcome-message {
  height: 250px;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 46em) {
  .tela-home-externa .welcome-message {
    overflow: hidden;
    
    height: 135px;
  }
}

.tela-home-externa .show-welcome-message-button {
  display: none;
}

@media screen and (max-width: 46em) {
  .tela-home-externa .show-welcome-message-button {
    display: inline-block;
  }
}

.tela-home-externa .supported-companies-title {
  text-align: left;
  font-size: x-large;
}

.tela-home-externa button.select-slide:focus,
.tela-home-externa button.select-slide:hover {
  background-color: var(--color-home-buttonmain);
}

.tela-home-externa button.select-slide.selected {
  background-color: var(--color-primary);
}
