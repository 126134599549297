.displaySelect {
  margin-top: 10px;
  margin-bottom: 15px;
  margin-left: 1.5px;
  margin-right: 1.5px;
}

.displaySelect .Select-input:focus {
  outline: none !important;
}

.displaySelect .Select-control .Select-input:focus {
  background: inherit;
}

.displaySelect input,
.table-inline-grid-grupo-produto .displaySelect input {
  border: 2px solid var(--color-input-background) !important;
  background: white !important;
  padding: 3px !important;
}

.table-inline-grid-grupo-produto .displayLabel {
  grid-area: 1 / 2 / 2 / 3;
  text-align: right;
}

.table-inline-grid-grupo-produto .displaySelect {
  grid-area: 1 / 3 / 2 / 5;
}

.accordion-item .row .row,
.accordion-item .row.row,
.accordion-item .row .column {
  display: inline-flex;
  flex-direction: row;
  width: 100%;
  padding: 0;
}

.accordion-item .row .left {
  float: left;
}

.accordion-item .row .right {
  float: right;
}

.accordion-item .row .column > .dialog-button {
  float: right;
  min-width: calc(1.8rem + 0.5vw);
  width: calc(1.8rem + 0.5vw);
  margin-right: 15px;
}

.accordion-item .row .column > .input-group {
  float: right;
  min-width: calc(6rem + 0.6vw);
  width: calc(8rem + 0.6vw);
}

.accordion-item .row .column > .input-group > .quant-button {
  min-width: calc(1.8rem + 0.6vw);
  width: calc(1.8rem + 0.6vw);
}

.accordion-item .row .column > .input-group > .quantidade {
  width: calc(6rem + 0.6vw);
  text-transform: uppercase;
  background-color: var(--color-background);
}

.accordion-item .row .column > .input-group > .quantidade.zerada {
  color: var(--color-text-link);
  font-weight: normal;
}

.accordion-item .row .column > .input-group > .quantidade .input-quantidade {
  width: 100%;
  border: none;
  background-color: var(--color-background);
  text-align: center;
}

.accordion-item .row.i-1 > .column.left {
  width: 170%;
}

.accordion-item .row.i-1 > .column.right {
  display: inline-block;
}

.accordion-item .row.i-2 > .column.right {
  display: inline-block;
  min-width: fit-content;
  width: auto;
  padding-right: 3px;
}

.accordion-item .row.i-2 > .column.right > .column.right {
  width: 75%;
  justify-content: flex-end;
}

.accordion-item .row.i-2 > .column.right > .column.left {
  width: 25%;
}

.accordion-item .row.i-2 > .column.isMSBrowser {
  width: 100%;
  padding-right: 0;
}

.accordion-item .row.i-2 > .column.isMSBrowser.right {
  min-width: 160px;
}

.accordion-item .row.i-2 > .column.isMSBrowser.left {
  width: 150%;
}

.accordion-item .row.i-2 > .column.isMSBrowser > .column {
  display: inline-flex;
  min-width: 78px;
}

.grid-grupo-produto-modal {
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(5, 5, 5, 0.5);
  z-index: 9999;
}
