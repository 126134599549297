#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo {
  width: 100%;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .feriado-informacao > * {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .feriado-informacao .Select-input:focus {
  outline: none !important;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .feriado-informacao .Select-control .Select-input:focus {
  background: inherit;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .feriado-horarios {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .feriado-horarios > .feriado-horarios__horario {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .feriado-horarios > .feriado-horarios__horario > span {
  width: 2.2em;
  margin-right: 0.5em;
  text-align: end;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .aviso {
  display: flex;
  height: 2em;
  margin-top: 1em;
  font-weight: 700;
  font-size: 1.2em;
  color: red;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .botoes {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .botoes .button-primary {
  background: var(--color-primary);
  color: white;
  width: 15em;
  min-height: 2.5em;
  font-weight: 400;
  margin: 0 0.8em;
}

#dialog-feriados-edicao > .dialog-feriados-edicao-conteudo > .botoes .button-primary:disabled {
  background: var(--color-mid);
}