
.tela-login .button-grid {
  padding: 10px 15vw;
  grid-gap: 5px;
  -ms-grid-rows: 1fr 5px 1fr;
  -ms-grid-columns: 1fr;
      grid-template: "btn1" 1fr 
                      "btn2" 1fr /
                      1fr;
}

.tela-login .button-grid button {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: btn1;
}

.tela-login .button-grid a {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: btn2;
}

.tela-login .button-grid > * {
  float: left;
}

.tela-login a {
  font-size: var(--size-home-link-text);
  text-decoration: underline;
  width: fit-content;
  height: fit-content;
}

.change-pass {
  display: flex;
  justify-content: center;
  width: 100% !important;
  margin-top: 8px;
  border-radius: var(--default-border-radius-button);
}

.change-pass:hover,
.change-pass:focus {
  background-color: var(--color-gray-button-focused) !important;
  color: #fff !important;
}
