/* Telas iniciais antes de logar no sistema */
.tela-start {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
}

.tela-start .button-grid > button {
  font-weight: bold;
  font-size: var(--size-home-button-text);
}

.tela-start .button-grid > button:hover,
.tela-start .button-grid > a:hover {
  filter: var(--hover-filter);
}

.tela-start .button-grid > button:active,
.tela-start .button-grid > a:active {
  filter: var(--active-filter);
}
