button.botao-flutuante {
  position: absolute;
  bottom: 1em;
  right: 0.05em;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  height: 5em;
  background-color: white;
  color: black;
  border: var(--global-borda);
  border-color: #ddd;
  box-shadow: var(--global-sombra);
  border-radius: 50%;
}

button.botao-flutuante > * {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 60%;
}

button.botao-flutuante > .quantidade-itens {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  height: 35%;
  border-radius: 0.5em;
  font-weight: bolder;
}