.produto-tela {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  
  width: 100%;
  max-width: 50em;
  height: auto;

  margin: 0.5em auto;

  font-family: 'Roboto';
}

.produto-tela > .grupo-produto-titulo {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;
  text-align: center;

  width: 100%;
  height: 100%;

  border-top-left-radius: 0.3em;
  border-top-right-radius: 0.3em;

  padding: 0.5em 0;
  font-size: 1.2em;
  font-weight: 500;
}

.produto-tela.unidade .quantidade-geral {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;

  font-size: 1.2em;

  width: 100%;
  margin-top: 1em;
  position: sticky;
  bottom: 0;

  align-items: center;
  justify-content: center;

  background-color: var(--cor-de-fundo);
}

.produto-tela.unidade .quantidade-geral > label {
  text-align: center;
  font-size: 0.9em;
}

.produto-tela.unidade .quantidade-geral > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;

  margin: 0.5em;
}

.produto-tela.unidade .quantidade-geral > div > .ui-botao,
.produto-tela.unidade .quantidade-geral > div > .ui-entrada {
  font-family: 'Arial';
  font-weight: 400;
}

.produto-tela.unidade .quantidade-geral > div > .ui-botao {
  width: 1.5em;
}

.produto-tela.unidade .quantidade-geral > div > .ui-botao.diminuir-quantidade {
  border-radius: 0.3em 0 0 0.3em;
}

.produto-tela.unidade .quantidade-geral > div > .ui-botao.aumentar-quantidade {
  border-radius: 0 0.3em 0.3em 0;
}

.produto-tela.unidade .quantidade-geral > div > * {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
}

.produto-tela.unidade .quantidade-geral > div > .quantidade {
  width: 2em;

  padding: 0.25em 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: var(--cor-de-fundo);
  color: var(--cor-texto);
  text-align: center;
}