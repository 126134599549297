.botao-checado {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;

  align-items: center;
  justify-content: center;

  width: 1em;
  height: 1em;

  border-radius: var(--default-border-radius-button);

  padding: 0.2em;

  font-size: 1.5em;
  font-weight: 500;
  color: white;

  background: var(--color-secondary);
}

.botao-checado.desativado {
  background: rgba(153, 153, 153, 0.7);
}