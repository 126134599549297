#carrinho-tela {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  
  width: 100%;
  height: auto;

  background: var(--cor-de-fundo);
  color: var(--cor-de-fundo-texto);

  font-family: 'Roboto';
}

#carrinho-tela > * {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: auto;
  min-height: 100%;
}

#carrinho-tela .linha-separadora {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;

  margin: 0;

  width: 100%;
  height: 1px;

  background: rgb(128, 128, 128);

  font-weight: lighter;
}

#carrinho-tela > #carrinho-rodape {
  width: 100%;
  position: sticky;
  bottom: 0;
  min-height: unset;
  max-width: 50em;
}

#carrinho-tela > #carrinho-rodape > #carrinho-preco-total {
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 1.2em;
  padding: 0.8em 0;
  background-color: var(--cor-de-fundo);
  width: 100%;
}

#carrinho-tela > #carrinho-rodape > #carrinho-preco-total > .campo {
  margin-right: 0.3em;
}

#carrinho-tela > #carrinho-rodape > #carrinho-preco-total > .valor {
  color: var(--cor-preco);
  font-weight: 500;
}

#carrinho-tela > #carrinho-vazio {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 10em;

  font-size: 2em;
  font-weight: bolder;
  color: var(--borda-cor);
}
