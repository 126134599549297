.balao-texto:after {
  left: 13%;
}

.menu-modal {
  top: calc(var(--barra-topo-altura) - 0.3em);
  left: 0.7em;
  
  position: fixed;
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;

  width: 13.5em;
  height: auto;

  padding-top: 8px;

  background: var(--cor-do-dialog);

  color: var(--cor-do-dialog-texto);
  font-size: 1.2em;

  z-index: 9;
}