.tela-redefine-senha .button-grid {
  padding: 10px 15vw;
  display: block;
}

.tela-redefine-senha button {
  padding: calc(8px + 0.5vw);
  font-weight: bold;
  font-size: var(--size-home-button-text);
  float: right;
}