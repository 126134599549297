#quantidade-dialog {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background: white;
  border-radius: 0.3em;
  padding: 1.2em;
}

#quantidade-dialog * {
  font-family: 'Roboto';
}

#quantidade-dialog > .conteudo {
  margin-bottom: 1em;
}

#quantidade-dialog > .conteudo > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-between;
  font-size: 1.1em;
  width: 100%;
}

#quantidade-dialog > .conteudo > div > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  margin: 0 0.3em;
}

#quantidade-dialog > .conteudo > .quantidade {
  margin-bottom: 0.7em;
}

#quantidade-dialog > .conteudo > .quantidade > div > * {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
}

#quantidade-dialog > .conteudo > .quantidade > div > .ui-botao,
#quantidade-dialog > .conteudo > .quantidade > div > .ui-entrada {
  font-family: 'Arial';
  font-weight: 400;
}

#quantidade-dialog > .conteudo > .quantidade > div > .ui-botao {
  width: 1.5em;
}

#quantidade-dialog > .conteudo > .quantidade > div > .ui-botao.diminuir-quantidade {
  border-radius: 0.3em 0 0 0.3em;
}

#quantidade-dialog > .conteudo > .quantidade > div > .ui-botao.aumentar-quantidade {
  border-radius: 0 0.3em 0.3em 0;
}

#quantidade-dialog > .conteudo > .quantidade > div > .editar-quantidade {
  width: 2em;
  padding: 0.25em 0;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: #f5f5f5;
  color: var(--cor-texto);
  text-align: center;
}

#quantidade-dialog > .conteudo > .valor-total > .valor {
  font-size: 0.9em;
  color: var(--cor-preco);
  padding-right: 3px;
}

#quantidade-dialog > .botoes {
  display: flex;
  flex-flow: row nowrap;
}

#quantidade-dialog > .botoes > .ui-botao {
  width: 5em;
  margin: 0.5em 0.5em 0;
}