#principal-tela {
  position: absolute; /* Necessário para Mac e iOS */
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  background: var(--cor-de-fundo);
  color: var(--cor-de-fundo-texto);

  width: 100%;
  height: 100%;
  
  padding: 0;
  margin: 0;

  font-size: 1.1em;
  font-weight: 500;

  overflow-y: hidden;
}

#principal-tela #barra-aberto-fechado {
  display: flex;
  background-color: red;
  color: white;
  height: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 0.8em;
}

#principal-tela > .conteudo {
  position: relative; /* Necessário para Mac e iOS */
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: auto;

  z-index: 8;

  background: var(--cor-de-fundo);

  overflow-y: auto;
}

#principal-tela > .conteudo > .conteudo-principal {
  position: relative; /* Necessário para Safari */

  width: 100%;
}

/* Necessário para Safari */
#principal-tela > .conteudo > .conteudo-principal > div {
  position: absolute; /* Necessário para Safari */

  margin: 0.25em 0.75em;

  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 1.5em);
  height: auto;

  z-index: 7;
}

@media screen and (min-width: 1270px) {
  #principal-tela {
    font-size: 1.2em;
  }
}