#barra-grupo-produto {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: auto;

  background: var(--cor-de-fundo);

  padding: 0;
  margin: 0;

  color: var(--cor-texto);

  z-index: 9;

  position: sticky;
  top: 0;
}

#barra-grupo-produto > #barra-grupo-alinhado-ao-centro {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

  overflow-x: auto;
  overflow-y: hidden;

  margin: 0;
  padding: 0;
}

#barra-grupo-produto > #barra-grupo-alinhado-ao-centro > #barra-grupo-produto-conteudo {
  position: relative;

  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: auto;
  height: var(--barra-grupo-produto-altura);

  margin: 0;
  padding: 0;
}

#barra-grupo-produto > #barra-grupo-alinhado-ao-centro > #barra-grupo-produto-conteudo > a,
#barra-grupo-produto > #barra-grupo-alinhado-ao-centro > #barra-grupo-produto-conteudo > .ui-botao {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: auto;
  min-width: 7em;
  height: calc(var(--barra-grupo-produto-altura) - 1.25em);

  margin: 0 8px;
  padding: 0.5em 0.5em;

  border: none;
  border-radius: 0.2em;

  text-decoration: none;
  font-family: 'Roboto';
  font-size: 0.8em;
  font-weight: 400;
  white-space: nowrap;
}

#barra-grupo-produto > #barra-grupo-alinhado-ao-centro > #barra-grupo-produto-conteudo > a:focus,
#barra-grupo-produto > #barra-grupo-alinhado-ao-centro > #barra-grupo-produto-conteudo > .ui-botao:focus {
  background: var(--cor-de-fundo);
  color: var(--cor-texto);
  border: var(--borda);
}