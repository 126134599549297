#redefinicao-senha-tela {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
}

#redefinicao-senha-tela div {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}

#redefinicao-senha-tela div > .ui-botao {
  padding: 0.7em;
}