#nova-senha-dialog {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
}

#nova-senha-dialog * {
  font-family: 'Roboto';
}

#nova-senha-dialog .ui-entrada {
  font-size: 1em;
}

#nova-senha-dialog .ui-botao {
  width: 5em;
  height: 1.5em;
  font-weight: 400;
}

#nova-senha-dialog .ui-botao:disabled {
  display: flex !important;
  opacity: 0.8;
}