#barra-topo {
  top: 0;
  
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;

  width: 100%;
  height: var(--barra-topo-altura);

  background: var(--cor-barra-topo);
  color: var(--cor-barra-topo-texto);

  z-index: 9;

  -webkit-tap-highlight-color: transparent;
}

#barra-topo > * {
  position: relative;
  
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  
  align-self: center;
  justify-self: center;

  color: var(--cor-barra-topo-texto);
}

#barra-topo > .menu {
  cursor: pointer;
  min-width: 65px;
  margin-left: 0.2em;
}

#barra-topo > .menu > .ui-icone {
  width: 2em;
  height: 2em;
}

#barra-topo > .menu > .notification {
  position: absolute;

  left: 1.65em;

  width: 0.7em;
  height: 0.7em;

  background: red;

  border-radius: 50%;
  border: 1px solid lightgray;
}

#barra-topo > .logo,
#barra-topo  > .nome-exibicao {
  justify-content: center;
  font-size: 0.8em;
}

#barra-topo > .logo > .imagem-logo,
#barra-topo  > .nome-exibicao > .imagem-logo {
  width: auto;
  height: 2em;
}

#barra-topo > .nome-exibicao {
  align-items: center;

  width: 100%;

  font-size: 1.35em;
  font-weight: 400;
  text-align: center;
}

#barra-topo > .nome-exibicao > .ui-texto {
  cursor: pointer;
}

#barra-topo > .ajuda > .ui-icone {
  width: 2em;
  height: 2em;
}

#barra-topo > .logo {
  cursor: pointer;
  margin-right: .2em;
  width: 65px;
}