#informacao-empresa-dialog {
  position: fixed;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;

  width: 100%;
  height: 100%;

  background: rgba(1, 1, 1, 0.5);
  z-index: 10;

  font-family: 'Roboto';
  font-weight: 400;
}

#informacao-empresa-dialog > .dialog-frame {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  align-items: center;

  width: 92%;
  height: 90%;

  color: var(--cor-do-dialog-texto);
  background: var(--cor-do-dialog);
  border: var(--dialog-borda);
  border-radius: var(--dialog-borda-raio);

  position: relative;
}

#informacao-empresa-dialog > .dialog-frame > div {
  width: 100%;
}

#informacao-empresa-dialog > .dialog-frame .barra-titulo {
  font-size: 1.4rem;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#informacao-empresa-dialog > .dialog-frame .barra-titulo .ui-botao {
  top: 0;
  right: 0;
  position: absolute;
  color: black;
  border-style: none;
  background: transparent;
  padding: 12px;
  font-size: 1.8rem;
}

#informacao-empresa-dialog > .dialog-frame .abas {
  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#informacao-empresa-dialog > .dialog-frame .abas > .abas-botoes {
  display: flex;
  flex-grow: 1;
}

#informacao-empresa-dialog > .dialog-frame .abas > .abas-botoes .ui-botao {
  cursor: pointer;
  color: black;
  border-style: none;
  background: transparent;
  height: 50px;
  font-family: 'Roboto';
  font-weight: 500;
  flex-grow: 1;
  border-radius: 0;
  border-bottom: transparent 2px solid;
}

#informacao-empresa-dialog > .dialog-frame .abas > .abas-botoes .ui-botao.selecionado {
  color: var(--cor-principal);
  border-bottom-color: currentColor;
}

#informacao-empresa-dialog > .dialog-frame .conteudo {
  display: flex;
  flex-grow: 1;

  overflow-y: auto;
  overflow-x: hidden;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > div {
  margin: 0 20px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .animation {
  animation: slideFromRight 200ms;
}

@keyframes slideFromRight {
  0% {
    transform: translateX(50vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-50vw);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-sobre {
  margin-top: 25px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-sobre > div {
  margin-bottom: 25px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-sobre > div > .sobre-titulo {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 5px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-sobre > div > .sobre-dados {
  font-size: 1rem;
  display: flex;
  line-height: 25px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-sobre > div > .sobre-dados a {
  text-decoration: none;
  color: currentColor;
  margin-left: 5px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios {
  margin-top: 25px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .horarios-funcionamento {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;

  font-family: 'Roboto';
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .horarios-funcionamento > .horario-funcionamento-item {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;

  margin-bottom: 1em;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .horarios-funcionamento > .horario-funcionamento-item > .horario-funcionamento-item-dia {
  display: flex;
  flex: 1 1 auto;
  align-items: flex-start;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .horarios-funcionamento > .horario-funcionamento-item > .horario-funcionamento-item-dia > .dia-semana {
  width: 30px;
  margin-right: 15px;
  font-size: 1.1em;
  font-weight: 500;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .horarios-funcionamento > .horario-funcionamento-item > .horario-funcionamento-item-dia > .dia-mes {
  width: 40px;
  margin-right: 15px;
  font-size: 1em;
  font-weight: 500;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .horarios-funcionamento > .horario-funcionamento-item > .horario-funcionamento-item-dia > .horarios {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .horarios-funcionamento > .horario-funcionamento-item > .horario-funcionamento-item-dia > .horarios > .horarios-item {
  font-size: 0.9em;
  font-weight: 400;
  white-space: nowrap;
  margin-left: 5px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo .aba-horarios .titulo {
  font-size: 1.1em;
  font-weight: 500;
  margin-top: 0.1em;
  margin-bottom: 0.5em;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-entrega {
  margin-top: 25px;
  width: 100%;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-entrega > .bairros {
  padding-bottom: 1px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-entrega > .bairros > .bairro-item {
  display: flex;
  justify-content: space-between;

  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-entrega > .bairros > .bairro-item:last-child {
  border-bottom: none;
  margin-bottom: 5px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-pagamento {
  margin-top: 25px;
}

#informacao-empresa-dialog > .dialog-frame .conteudo > .aba-pagamento > .formas-pagamento div {
  margin-bottom: 20px;
}

#informacao-empresa-dialog > .dialog-frame .rodape {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
}

#informacao-empresa-dialog > .dialog-frame .rodape div {
  margin: 14px;
}

#informacao-empresa-dialog > .dialog-frame .rodape p {
  margin-block: 0.8em;
}

#informacao-empresa-dialog > .dialog-frame .rodape a {
  text-decoration: none;
  color: currentColor;
  font-weight: 500;
}

@media screen and (min-width: 480px) {
  #informacao-empresa-dialog > .dialog-frame {
    max-width: 25em;
  }
}

@media screen and (min-height: 720px) {
  #informacao-empresa-dialog > .dialog-frame {
    max-height: 30em;
  }
}