.primary-shine {
  background-image: linear-gradient(55deg, var(--color-toolbar-buttonmain), var(--color-toolbar-buttonmain), var(--color-toolbar-buttonmain), var(--color-toolbar-buttonmain-shine), var(--color-toolbar-buttonmain), var(--color-toolbar-buttonmain), var(--color-toolbar-buttonmain)) !important;
}

.primary-shine:hover,
.primary-shine:focus {
  background-image: linear-gradient(55deg, var(--color-primary), var(--color-primary), var(--color-primary), var(--color-toolbar-buttonmain-shine), var(--color-primary), var(--color-primary), var(--color-primary)) !important;
}

.primary-shine,
.primary-shine:hover,
.primary-shine:focus {
  background-size: 500% 500% !important;
  animation: GradientAnimation 2s ease infinite !important;
}

#pageInfo:hover,
#pageInfo:focus {
  background-color: var(--color-gray-button-focused);
}

@-webkit-keyframes GradientAnimation {
  from {background-position: 100% 50%};
  to {background-position: 0% 50%};
}

@-moz-keyframes GradientAnimation {
  from {background-position: 100% 50%};
  to {background-position: 0% 50%};
}

@-o-keyframes GradientAnimation {
  from {background-position: 100% 50%};
  to {background-position: 0% 50%};
}

@keyframes GradientAnimation {
  from {background-position: 100% 50%};
  to {background-position: 0% 50%};
}
