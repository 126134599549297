.tela-sign-up-user {
  display: block;
}

.tela-sign-up-user a {
  text-decoration-line: underline;
  cursor: pointer;
}

.cancel-validation {
  width: auto;
}

.cancel-validation:hover,
.cancel-validation:focus {
  background-color: var(--color-gray-button-focused) !important;
  color: #fff !important;
}
