.table {
  margin: 5px 1.5px;
  background: var(--color-table-background);
}

.table > .table-row > .table-cell {
  font-size: var(--size-table-row-text);
  background: var(--color-table-row-background);
}

.table-header {
  padding: 10px;
  font-size: var(--size-table-header-text);
  background: var(--color-table-header-background);
  color: var(--color-text-dark);
}

.table-footer {
  font-size: var(--size-table-footer-text);
  background-color: var(--color-table-footer-background);
}

.table-footer .table-cell {
  background: none !important;
  background-color: none !important;
}

.table-columns-header {
  margin: 0.5px;
  padding: 5px 0 3px 0;
  white-space: nowrap;
  background: var(--color-table-content-background);
}

.table-columns-header > .table-cell {
  font-size: var(--size-table-columns-header-text);
  padding: 0 4px;
}

.table-cell {
  padding: 2px 4px;
  margin: 0 1px;
  -ms-flex-item-align: center;
	align-self: center;
  white-space: nowrap;
	overflow: hidden;
}
	
.table-cell.input-group {
  padding: 0;
}

.table-button {
  background: var(--color-table-button-background);
  padding: 0;
  margin: 0;
}
	
.table-button:disabled {
  background: var(--color-table-button-disabled-background);
}

.table-total {
  display: grid;
  padding: 1px 8px;
  font-size: var(--size-table-total-text);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.table-total > .TextAlign {
  grid-column: 1/2;
  float: left;
}

.table-total > .NumberAlign {
  grid-column: 2/3;
  float: right;
}

/* Tabela de itens de venda - Origens em uso / Origem 001: Vendas / Venda 1: Produtos / Itens */
.table-items.table-columns-header,
.table-items.table-row {      
  grid-template-columns: 2fr 8fr 3fr 3fr 3fr 3fr 2fr;
}

/* Tabela de pagamento - Origens em uso / Origem 001: Vendas / Pagamento
                       - Origens em uso / Origem 001: Vendas / Venda 1: Produtos / Pagamento */
.table-pagamento.table-columns-header,
.table-pagamento.table-row {
  grid-template-columns: auto 5fr 1.7fr 2.7fr 70px 3.2fr;
}

/* Tabela de Coletor (única linha) - Coletores em uso / Cliente João */
.table-collector.table-columns-header,
.table-collector.table-row {
  grid-template-columns: 2fr 10fr 4fr 4fr 4fr;
}

/* Tabela de cancelamento de pagamento */
.table-cancel.table-columns-header,
.table-cancel.table-row {
  grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr;
}
    
.table-items.table-columns-header,
.table-items.table-row,
.table-pagamento.table-columns-header,
.table-pagamento.table-row,
.table-collector.table-columns-header,
.table-collector.table-row,
.table-cancel.table-columns-header,
.table-cancel.table-row {
  display: grid;
  grid-template-rows: auto;
}

.table-items > .del {
  grid-column: 1/2;
}

.table-items > .product {
  grid-column: 2/3;
}

.table-items > .price {
  grid-column: 3/4;
}

.table-items > .amount {
  grid-column: 4/5;
}

.table-items > .total {
  grid-column: 5/6;
}

.table-items > .time {
  grid-column: 6/7;
}

.table-items > .obs {
  grid-column: 7/8;
}

.table-pagamento > .check {
  background-color: transparent !important;
  grid-column: 1/2;
}

.table-pagamento > .product {
  grid-column: 2/3;
}

.table-pagamento > .amount {
  grid-column: 3/4;
}

.table-pagamento > .price {
  grid-column: 4/5;
}

.table-pagamento > .pay {
  grid-column: 5/6;
}

.table-pagamento > .pricePlusPay {
  grid-column: 4/6;
}

.table-pagamento > .total {
  grid-column: 6/7;
}

.table-pagamento > .quebra {
  grid-column: 2/7;
}

.table-cancel > .check {
  background-color: transparent !important;
  grid-column: 1/2;
}

.table-cancel > .day {
  grid-column: 2/4;
}

.table-cancel > .hour {
  grid-column: 4/5;
}

.table-cancel > .saleSourceSale {
  grid-column: 5/8;
}

.table-cancel > .product {
  grid-column: 2/6;
}

.table-cancel > .amount {
  grid-column: 6/7;
}

.table-cancel > .total {
  grid-column: 7/8;
}

/* Tabela de Coletor (duas linhas) - Coletores em uso / Cliente João */
@media screen and (max-width: 35.5em) {
  .table-collector.table-columns-header,
  .table-collector.table-row {
    grid-template-columns: 1fr 3fr 4fr 4fr;
  }

  .table-collector .del {
    grid-row: 1/3;
    grid-column: 1/2;
    -ms-grid-row-align: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    position: relative;
  }

  .table-collector .del button {
    height: 100%;
  }

  .table-collector .product {
    grid-row: 1/2;
    grid-column: 2/5;
    -ms-grid-row-align: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
  }
}

.table-footer > .table-pagamento.table-row,
.table-footer > .table-cancel.table-row {
  font-size: inherit;
}

.table-pagamento.table-footer,
.table-cancel.table-footer {
  margin-bottom: 6px;
}

.table-pagamento.table-footer .table-row,
.table-cancel.table-footer .table-row {
  background: var(--color-table-background);
}

.table.itens-venda-pagar > .table-pagamento.table-row.marked { /* Esconde itens pagos na tela a pagar */
  display: none;
}

/* Pure table - alterar para Table no futuro */
.pure-table th,
.pure-table .pure-table td {
  padding: calc(3px + 0.5vw) !important;
}

.pure-table th {
  font-size: var(--size-table-header-text) !important;
  background: var(--color-table-background);
  text-align: center;
}

.pure-table td {
  font-size: var(--size-table-row-text) !important;
}

table.table-template {
  display: block;
  overflow: auto;
}

table.table-template > *,
table.table-template > thead,
table.table-template > tbody,
table.table-template tr,
table.table-template th,
table.table-template td {
  display: flex;
  width: calc(100% - 2px);
}

table.table-template th,
table.table-template td {
  justify-content: center;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  min-width: 100px;
}

table.table-template > thead,
table.table-template > tbody {
  flex-direction: column;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  min-width: fit-content;
}

table.table-template > thead {
  margin-bottom: -1px;
  background-color: var(--color-table-light-header-background);
  font-size: var(--size-table-header-text);
}

table.table-template > tbody {
  margin-top: -1px;
  background-color: var(--color-table-light-background);
  font-size: var(--size-table-row-text);
}

.timesheetEditor > * {
  margin: 15px 0;
}