.f3-button {
  background-color: #1877f2;
  border: 1px solid #1877f2;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 2px;
  padding: 4px 6px 2px 6px;
  text-align: center;
  width: auto;
}

.f3-button-innerHTML {
  display: inline-flex;
  white-space: nowrap;

  height: auto;
}

.f3-button-innerHTML > img {
  margin-right: 8px;
}

.f3-button-innerHTML > img.user-picture {
  height: 54px;
}