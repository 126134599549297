/* Fonte padrão dos elementos do Pure CSS */
body, .pure-g [class*="pure-u"],
.pure-g-r [class*="pure-u"], .pure-table *  {
  font-family: font-ampix-mobi, 'Open Sans', sans-serif !important;
  font-weight: lighter;
}

h1, h2, h3 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 3em;
  font-weight: normal;
}

h2, h3 {
  color: var(--color-mid);
  font-weight: lighter;
}

.NumberAlign {
  text-align: right;
}

.TextAlign {
  text-align: left;
}

.CenterAlign {
  text-align: center;
}

.ButtonAlign {
  text-align: center;
}

.Uppercase {
  text-transform: uppercase;
}

.Lighter {
  font-weight: lighter;
}

.Bold {
  font-weight: bold;
}

.text-no-wrap {
  white-space: nowrap;
}