.combinacoes-tela-modal {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 85%;
  min-height: 85%;
  width: 50%;
  background: white;
}

.combinacoes-tela-modal .barra-titulo {
  display: flex;
  border-bottom: 1px solid var(--color-black-transparent);
}

.combinacoes-tela-modal .barra-titulo > h2 {
  color: var(--color-dialog-text);
  flex-grow: 1;
  padding: 0.3em 0.3em 0.3em 0.8em;
}

.combinacoes-tela-modal .barra-titulo > .botao-fechar {
  background-color: transparent;
  font-size: 1.8em;
  width: 1.5em;
}

.combinacoes-tela-modal .conteudo {
  overflow-y: auto;
  overflow-x: hidden;
}

.combinacoes-tela-modal .conteudo .combinacoes-grupo {
  justify-self: flex-start;
  flex-grow: 1;
  margin: 1em;
}

.combinacoes-tela-modal .conteudo .campo-observacao {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
  max-height: 25%;
  margin: 0 1.1em 1em 1.1em;
}

.combinacoes-tela-modal .conteudo .quantidade-adicionar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.1em 1em 1.1em;
}

.combinacoes-tela-modal .conteudo .quantidade-adicionar .label-quantidade {
  font-size: 1.8em;
  font-weight: 500;
  margin-bottom: 0.2em;
}

.combinacoes-tela-modal .conteudo .quantidade-adicionar .spinner-quantidade {
  height: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
}

.combinacoes-tela-modal .conteudo .quantidade-adicionar .spinner-quantidade button {
  min-width: 2.2em;
}

.combinacoes-tela-modal .conteudo .quantidade-adicionar .spinner-quantidade .quantidade {
  width: calc(6rem + 0.6vw);
  text-transform: uppercase;
  background-color: var(--color-background);
  display: flex;
  font-size: 1.2em;
}

.combinacoes-tela-modal .conteudo .quantidade-adicionar .spinner-quantidade .quantidade .input-quantidade {
  width: 100%;
  border: none;
  background-color: var(--color-background);
  text-align: center;
}

.combinacoes-tela-modal .conteudo .botao-adicionar {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 1.1em 1em 1.1em;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
}

.combinacoes-tela-modal .conteudo .botao-adicionar.desativado {
  position: unset;
  bottom: unset;
  opacity: 0.5;
}

.combinacoes-tela-modal .conteudo .botao-adicionar > .botao {
  padding: 0.5em;
  font-weight: 500;
  max-width: 12em;
}

@media screen and (max-width: 1700px) {
  .combinacoes-tela-modal {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .combinacoes-tela-modal {
    width: 95%;
  }
}

@media screen and (max-width: 945px), (max-height: 620px) {
  .combinacoes-tela-modal {
    max-height: 95%;
  }
}

@media screen and (max-width: 600px) {
  .build-cell-from-grupo-produto > h1 {
    padding-left: 1em !important;
    padding-right: 0.8em !important;
  }
}