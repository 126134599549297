#grupo-produto-tela {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  
  width: 100%;

  margin-top: 1em;

  font-family: 'Roboto';
}
