.landing-page-root {
  font-family: "Montserrat", sans-serif !important;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
  /* hack pra dar offset por usar position fixed no NavBar */
  scroll-margin-top: 75px !important;

  height: auto !important;
  display: initial !important;
}

.landing-page-root * {
  font-family: "Montserrat", sans-serif !important;
  box-sizing: border-box;
  /* hack pra dar offset por usar position fixed no NavBar */
  scroll-margin-top: 75px;
}

html {
  scroll-behavior: smooth;
}
