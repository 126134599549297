.cadastro-comanda.left {
  width: calc(100% - 4px);
  display: flex;
  align-self: flex-start;
  margin-right: 2px;
}

.cadastro-comanda.center {
  width: calc(100% - 4px);
  display: flex;
  align-self: center;
  margin-left: 2px;
}

.cadastro-comanda.right {
  width: calc(100% - 4px);
  display: flex;
  align-self: flex-end;
  margin-left: 2px;
}

.label-cadastro-comanda {
  margin: 0.5em 0 0.125em;
  font-weight: 500;
}

.displaySelect.telaPedido {
  min-width: 20em;
  margin: 10px 10px 0px 10px;
}

.topClassNameStyleTempoPedido {
  width: 9em;
  margin: 10px 10px 0px 10px;
}

.topClassNameStylePreCadastroTela {
  margin-top: 0.2em;
  width: 100%;
  flex-grow: 1;
  min-width: 10em;
}

.topClassNameStylePreCadastroTela.grande {
  width: 50%;
}

.topClassNameStylePreCadastroTela.medio {
  width: 30%;
}

.topClassNameStylePreCadastroTela.pequeno {
  width: 20%;
}

.topClassNameStylePreCadastroTela .Select-menu {
  max-height: 152px !important;
}

.resultados-busca-header {
  border: 1px solid rgba(0, 29, 73, 0.2);
  margin-top: 2px;
  margin-left: 2px;
  margin-right: 2px;
  border-radius: var(--default-border-radius-input);
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  left: 50%;
  z-index:10;
  -webkit-box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 6px 14px 0 rgba(0, 0, 0, 0.1);
}

.resultados-busca-header .resultados-busca-body {
  background: #e3e3e3;
  max-height: 480px;
  padding: 5px 0;
  overflow: auto;
  border-radius: var(--default-border-radius-input);
}

.resultados-busca-header .resultados-busca-body ul {
  list-style: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.resultados-busca-header .resultados-busca-body ul li {
  height: 2.2em;
  display: flex;
  align-items: center;
  padding-left: 0.6em;
}

.resultados-busca-header .resultados-busca-body ul li[data-active = 'true'] {
  background-color: rgb(240, 240, 240);
}

@media screen and (max-width: 680px) {
  .resultados-busca-header {
    left: 0;
  }
}

.tempo-cadastro-pedido {
  width: 9em !important;
}

.pre-cadastro-tela-botoes {
  margin: 1em 0 0.2em 0;
  display: flex;
  align-self: center;
  flex-grow: 1;
}

.pre-cadastro-tela-botoes > * {
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.pre-cadastro-tela-botoes * {
  flex-grow: 1;
  min-width: 5em;
}

.pre-cadastro-tela-botoes .bar-button-label-text {
  display: inline;
}
  
.pre-cadastro-tela-botoes .botao-pre-cadastro {
  border: 2px solid;
  padding: 0 10px;
  background: none;
  color: var(--color-text-link);
}