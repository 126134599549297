#cadastro-box {
  height: 100%;
}

#cadastro-box > #conteudo-box {
  height: 100%;
}

#cadastro-box:not(.full_screen) {
  height: auto;
}

#cadastro-box:not(.full_screen) > #conteudo-box {
  height: auto;
}

.spaced-box-content > * {
  margin: 15px 0;
}
