#botao-cadastrar {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: 6em;
  height: 2.5em;

  border-color: var(--borda-cor);
  
  box-shadow: var(--borda-cor) 0.10em 0.10em;
}

#botao-cadastrar:disabled {
  display: none;
}
