.table-inline {
  display: block;
  height: auto;
}

.display {
  display: block;
  margin: 5px;
  width: 34.5%;
}

.discountTypeValue .Select-input:focus {
  outline: none !important;
}

.discountTypeValue .Select-control .Select-input:focus {
  background: inherit;
}

.table-inline .commissionInput input,
.table-inline .discountFieldValue input,
.table-inline .payedValue input,
.table-inline .moneyChange input {
  border: 2px solid var(--color-input-background) !important;
  background: white !important;
  padding: 3px !important;
}

.table-inline .discountTypeValue,
.table-inline .paymentMethodInput {
  text-align: left;
}

.table-inline .commissionDiscountButton button {
  display: block;
  min-width: 120px;
  width: 27%;
  float: right;
  margin-top: 22px;
  height: 80%;
  background: none;
  border: 2px solid;
  color: var(--color-text-link);
  font-weight: bold;
  text-transform: uppercase;
}

.table.itens-venda-pagar,
.table.itens-venda-pagos,
.table.total-pagar-pagos {
  margin-top: 15px;
}

.table > .table-footer.build-cell-from-quebra-venda-origem .row {
  display: inline-grid;
}

.table .with-discount .total.NumberAlign,
.table .without-discount .total.NumberAlign {
  padding-right: 5px;
}

.table .table-cell.pay > .quantidade {
  background-color: whitesmoke;
  text-align: center;
  min-width: 20px;
}

.table .table-cell.pay > .quant-button {
  border: 2px solid var(--color-table-row-background);
  border-radius: 2px; 
  background-color: var(--color-toolbar-buttonsecondary);
  width: 28px;
  min-width: 20px;
}

.table .row,
.table .column {
  display: inline-flex;
  width: 100%;
  padding: 0;
  margin: 0;
}
  
.table .left {
  float: left;
}

.table .right {
  float: right;
}

.table .align-right {
  text-align: right;
}

.table .row {
  flex-direction: row;
}

.table.ancient-chrome-mobile .table-cell {
  min-width: fit-content;
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header {
  display: inline-flex;
  width: 100%;
  margin: 0;
  padding: 0;
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header .table-cell {
  margin: 0;
  padding: 0;
  text-align: left;
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header .table-cell:not(.check) {
  width: 100%;
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header .table-cell.check {
  max-width: fit-content;
  width: 100%;
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header > .column {
  min-width: fit-content;
}


@media screen and (max-width:30em) {
  .table.ancient-chrome-mobile > .table-pagamento.table-columns-header > .column.left {
    width: 35%;
  }
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header > .column.right {
  display: inline-block;
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header > .column.right .table-cell {
  text-align: right;
  margin: 0 0.5px;
}

.table.ancient-chrome-mobile > .table-pagamento.table-columns-header .NumberAlign,
.table.ancient-chrome-mobile > .table-pagamento.table-columns-header .pay.ButtonAlign {
  text-align: right;
}

.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas {
  display: inline-flex;
  margin: 0;
  padding: 0;
  text-align: right;
  width: 100%;
}

.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas .table-cell {
  margin: 0 0.75px;
  padding: 0;
}
  
.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas .table-cell:not(.check) {
  background-color: rgba(255, 255, 255, 0.267);
  width: 100%;
}

.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas .table-cell.check {
  max-width: fit-content;
  width: 100%;
}

.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas .column.left {
  @media screen and (max-width:30em) {
    width: 35%;
  }
}

.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas .column.right {
  display: inline-flex;
}
  
.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas .column.right .table-cell {
  width: 100%;
  text-align: right;
  margin: 0 0.5px;
}

.table.ancient-chrome-mobile > .table-pagamento.table-row.build-cell-from-item-pagamento-vendas .input-group {
  width: 240px;
  height: auto;
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-origem {
  background-color: var(--color-table-footer-background);  
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-origem .table-pagamento.table-row {
  display: inline-flex;
  margin: 0;
  padding: 0;
  text-align: left;
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-origem .table-pagamento.table-row:not(.checkbox-form-cell) {
  width: 100%;
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-origem .table-pagamento.table-row.checkbox-form-cell {
  max-width: fit-content;
  width: 100%;
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-total {
  width: 100%;
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-total > .table-pagamento.table-row {
  display: inline-block;
  width: 100%;
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-total > .table-pagamento.table-row > .row {
  text-align: right;
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-total > .table-pagamento.table-row.without-discount > .row {
  margin: 0 7.5px;
  width: calc(100% - 7.5px*2);
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-total > .table-pagamento.table-row.with-discount > .row > .column.right {
  margin: 0 7.5px;
  width: calc(100% - 7.5px*2);
}

@media screen and (max-width:26em) {
  .table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-total > .table-pagamento.table-row.with-discount > .row > .column.left {
    width: 35%;
  }
}

.table.ancient-chrome-mobile > .table-footer.build-cell-from-quebra-venda-total .NumberAlign {
  min-width: fit-content;
  width: 100%;
}

.table.ancient-chrome-mobile .table-cell.total.NumberAlign {
  padding-right: 5px !important;
}

.TPVContent.row {
  text-align: left;
  flex-direction: row;
  margin-bottom: 5px;
}

.TPVContent.column {
  display: inline-flex ;
  padding-top: 7px;

  flex-direction: column;
  margin: auto 10px auto auto;
  width: 100%;
}

.TPVContent.column:last-child {
  margin-right: 0;
}

button.TPVContent {
  margin-top: 5px;
  padding: 5px;
  font-weight: bolder;
  background-color: var(--color-toolbar-buttonsecondary);
}

.table-cell {
  margin: 0 0.5px;
  padding: 2px (0.5px*4);
}

.table-pagamento.table-row.build-cell-from-item-pagamento-vendas > .table-cell {
  background-color: var(--color-table-row-background);
}

.table-pagamento.table-row.build-cell-from-item-pagamento-vendas > .table-cell.input-group > * {
  height: auto;
  display: block;
}

.table-pagamento.table-row.build-cell-from-item-pagamento-vendas > .table-cell.input-group > .quantidade {
  height: 19.7px;
  padding-top: 6px;
  font-size: 12px;
}