#animacao-carregamento {
  position: absolute;

  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  width: calc(1vw + 5em);
  height: calc(1vw + 5em);

  border: calc(4px + 1vw) solid var(--color-spinner-borda);
  border-radius: 50%;
  border-top-color: var(--color-spinner-spin);
  
  animation: spinner .7s linear infinite;
  
  z-index: 10;
}

@keyframes animacao-carregamento {
  to {transform: rotate(360deg);}
}
