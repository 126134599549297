input[type="file"] {
  display: none;
}

.upload-image {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  width: 100%;
}

.upload-image > div > label {
  height: fit-content;
  background-color: var(--color-secondary);
  border-radius: var(--default-border-radius-button);
  font-weight: bolder;
  color: var(--color-text-light);
  cursor: pointer;
  margin: 10px 5px;
  padding: 6px 20px;
}

.upload-image > .upload-wrapper > * {
  width: 9em;
  padding: 5px;
  font-size: 1.1em;
  font-weight: 500;
}

.upload-image > .upload-wrapper > button {
  margin-right: 5px;
  margin-left: 5px;
}

.upload-image > #image-container {
  display: none;
  text-align: right;
}

#current-image {
  width: auto;
  height: auto;
  max-width: 18em;
  max-height: 18em;
}

.uploaded-image-container {
  width: 10em;
  height: 10em;
  display: flex;
  justify-self: center;
  align-items:center;
  justify-content: center;
}

.uploaded-image-container #uploaded-image {
  width: auto;
  height: auto;
  max-width: 10em;
  max-height: 10em;
  justify-self: center;
  border-radius: 5px;
}