.balao-texto {
	position: relative;

	width: auto;
	height: auto;

	border: var(--borda);
	border-radius: .4em;

	background: var(--cor-do-dialog);
	
	color: var(--cor-do-dialog-texto);
}

.balao-texto:after {
	content: '';
	position: absolute;
	top: 0;

	left: 50%;

	width: 0;
	height: 0;

	border: 10px solid transparent;
	border-top: 0;
	border-left: 0;
	border-bottom-color: var(--cor-do-dialog);

	margin-left: -10px;
	margin-top: -10px;
}
