.modal-pedidos-tela {
  position: fixed;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(5,5,5,0.5);
  z-index: 9999;
}

.modal-pedidos-tela > .modal-couteudo-pedidos-tela {
  width: 50%;
  height: auto;

  background: rgb(250,250,250);

  overflow: auto;
}

.modal-pedidos-tela > .modal-couteudo-pedidos-tela > .modal-pedidos-tela-close {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  z-index: 1;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: inherit;
}

.modal-pedidos-tela > .modal-couteudo-pedidos-tela > .modal-pedidos-tela-close > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  
  width: 1em;
  height: 1em;

  cursor: pointer;

  background: transparent;

  font-weight: lighter;
  text-align: right;
  font-size: 1.8em;

  margin: 0.2em 0.1em 0 0;
}

@media screen and (max-width: 1700px) {
  .modal-pedidos-tela .modal-couteudo-pedidos-tela {
    width: 80%;
  }
}

@media screen and (max-width: 1100px) {
  .modal-pedidos-tela .modal-couteudo-pedidos-tela {
    width: 95%;
  }
}

@media screen and (max-width: 945px), (max-height: 620px) {
  .modal-pedidos-tela .modal-couteudo-pedidos-tela.pre-cadastro-tela {
    height: 95%;
  }
}
