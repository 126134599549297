@-webkit-keyframes hvr-buzz-sale-source {
  3.216765883467019% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  6.547010171317481% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  9.994676180614363% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  13.56404122618204% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  17.25975543972663% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  21.08688673712771% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  25.05097316704871% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  29.15808417832284% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  33.41489273805871% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  37.82876074865152% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  42.40784089433834% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  47.16119896004443% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  52.09896189882613% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  57.23249861410255% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  62.5746427705435% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  68.13997025935319% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  73.94514869511303% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  80.00938326597412% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  86.35499362092207% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  93.00817229930215% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  100% {
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }
}

@keyframes hvr-buzz-sale-source {
  3.216765883467019% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  6.547010171317481% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  9.994676180614363% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  13.56404122618204% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  17.25975543972663% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  21.08688673712771% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  25.05097316704871% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  29.15808417832284% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  33.41489273805871% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  37.82876074865152% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  42.40784089433834% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  47.16119896004443% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  52.09896189882613% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  57.23249861410255% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  62.5746427705435% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  68.13997025935319% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  73.94514869511303% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  80.00938326597412% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  86.35499362092207% {
    transform: rotate(15deg) translateX(+3.882285676537811px) translateY(0px);
  }
  93.00817229930215% {
    transform: rotate(-15deg) translateX(-3.882285676537811px) translateY(0px);
  }
  100% {
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }
}

.SaleSourceAlert {
  -webkit-animation-name: hvr-buzz-sale-source;
  animation-name: hvr-buzz-sale-source;
}
