#barra-de-busca {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;

  align-items: flex-start;
  justify-content: center;

  width: 100%;
  max-width: 50em;
}

#barra-de-busca > .ui-botao {
  margin-left: 0.25em;
  padding: 0.5em 0.7em;
}

#barra-de-busca > .ui-entrada {
  font-family: 'Roboto';
  font-size: 1.1em;
  font-weight: 400;
  margin: 0;
}