.produto-card {
  margin: 0;

  border: none;
  border-radius: 0 !important;

  background: var(--cor-do-card);
  color: var(--cor-do-card-texto);
  
  margin: 0 !important;
}

.produto-card .descricao {
  display: flex !important;
  flex: 1 0 auto !important;
  flex-flow: column nowrap !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;

  height: auto !important;
  max-height: 6em !important;
}

.produto-card .descricao > .ui-texto {
  font-size: 0.8em;
  font-weight: 400;
}

.produto-card > div {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  width: 100%;
  height: auto;

  padding: 0.1em 0;
}

.produto-card > div > div {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  margin: 0.5em 0;
}

.produto-card > div .imagem {
  position: relative;

  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  margin-left: 0.5em;
  margin-right: 0.5em;

  width: 3.5em;
  height: 3.5em;

  background: transparent;
}

.produto-card > div .imagem > .app-icone {

  color: transparent;
  width: 3.5em;
  height: 3.5em;
  border-radius: 5px;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.produto-card > div .imagem > .glass {
  position: absolute;

  color: white;
}

.produto-card > div .imagem > .glass > * {
  width: 1.5em;
  height: 1.5em;
}

.produto-card > div .imagem.hide {
  display: none;
}

.produto-card > div .identificacao-produto {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  margin-right: 0.5em;
  margin-left: 0.5em;
}

.produto-card > div .identificacao-produto.unidade {
  align-items: center;
  justify-content: center;
}



.produto-card > div .identificacao-produto > .nome > .info {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  height: fit-content;

  margin-bottom: 0.2em;
}

.produto-card > div .identificacao-produto > .nome > .info > div {
  padding: 0;
}

.produto-card > div .identificacao-produto > .nome > .info > .nome {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;

  min-width: 0;
}

.produto-card > div .identificacao-produto > .nome > .info > .nome > .ui-texto {
  overflow-wrap: break-word;

  font-size: 1em;
  font-family: 'Roboto';
  font-weight: 500;
  text-align: left;

  max-height: auto;
  min-width: 0;
}

.produto-card > div > .painel-produto {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
}

.produto-card > div > .painel-produto > div,
.produto-card > div > .painel-produto > .ui-botao {
  margin: 0 0.4em;
}

.produto-card > div > .linha-separadora {
  margin: 0;
  width: auto;
}

.produto-card .botoes-dialog {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;
}

.produto-card .botoes-dialog > div {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: flex-end;

  width: 100%;
}

.produto-card .botoes-dialog > div > .ui-botao {
  width: auto;
  height: auto;

  border: none;

  background: transparent;

  color: black;
}

.produto-card .botoes-dialog > div > .ui-botao > * {
  width: 2em;
  height: 2em;
}

.produto-card .painel-e-preco {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  margin-top: 0.7em;
}

.produto-card .painel-e-preco > .preco {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;

  white-space: nowrap;
  justify-content: flex-end;

  font-family: 'Roboto';
}

.produto-card .painel-e-preco > .preco > .sem-desconto {
  font-size: 0.8em;
  text-decoration: line-through;
  min-width: auto;
}

.produto-card .painel-e-preco > .preco > .com-desconto {
  font-size: 1em;
  font-weight: 500;
  color: var(--cor-preco);
}

.produto-card .painel-e-preco > .preco > .com-desconto.sold-out {
  color: darkred;
}

.produto-card .painel-e-preco > .novo-produto-tamanho {
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  width: 1em;
  height: 1em;

  font-size: 1.5em;
  
  margin: 0 0.4em;

  border-radius: 0.3em;
}

.produto-card .painel-e-preco > .controle-quantidade {
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-left: 0.5em;
  max-width: 6.5em;
}

.produto-card .painel-e-preco > .controle-quantidade > .ui-botao,
.produto-card .painel-e-preco > .controle-quantidade > .quantidade {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  font-size: 1.2em;
  align-items: center;
  justify-content: center;
  min-width: 1.5em;

  font-weight: 400;
  font-family: 'Arial';
}

.produto-card .painel-e-preco > .controle-quantidade > .ui-botao {
  min-width: 1.2em;

  padding: 0.25em;

  background: var(--cor-principal);

  border: none;
}

.produto-card .painel-e-preco > .controle-quantidade > .ui-botao.diminuir-quantidade {
  border-radius: 0.3em 0 0 0.3em;
}

.produto-card .painel-e-preco > .controle-quantidade > .ui-botao.aumentar-quantidade {
  border-radius: 0 0.3em 0.3em 0;
}

.produto-card .painel-e-preco > .controle-quantidade > .quantidade {
  width: 2em;
  max-height: auto;

  padding: 0.25em 0;

  margin: 0;
  
  border: none;
  border-radius: 0;
  
  background: var(--cor-da-entrada);
  
  color: var(--cor-da-entrada-texto);
  text-align: center;
}

.produto-card .painel-e-preco .remover-do-carrinho {
  width: auto;
  height: auto;
  border: none;
  background: transparent;
  color: black;
}

.produto-card .painel-e-preco .remover-do-carrinho > .ui-icone {
  width: 1.4em;
  height: 1.4em;
}

.produto-card .painel-e-preco .remover-do-carrinho.disabled {
  display: flex;
  color: transparent !important;
}