/* Container do input, Spinner e Clear dos campos */
.input-container {
  width: 100%;
  height: 100%;
  display: grid;
  padding: 2px;
  grid-template-columns: auto 1fr 1fr;
}

input[type=text],
input[type=number],
input[type=email],
input[type=password],
input[type=numeric],
textarea,
.maskedInputStyle {
  box-shadow:none;
  border: none;
  width: 100%;
  padding: 7px;
  display: inline-block;
  box-sizing: border-box;
  background: var(--color-input-background);
  color: var(--color-input-text);
  border-radius: var(--default-border-radius-input);
  border: var(--input-border);
}

input[type=text]:disabled, 
input[type=number]:disabled,
input[type=email]:disabled,
input[type=password]:disabled,
input[type=numeric]:disabled,
textarea:disabled,
.maskedInputStyle:disabled {
  background: var(--color-mid);
}

.observacao-item-textarea {
  box-shadow:none;
  border: none;
  width: 100%;
  padding: 7px;
  display: inline-block;
  box-sizing: border-box;
  background: var(--color-input-background);
  color: var(--color-input-text);
  border-radius: var(--default-border-radius-input);
  border: var(--input-border);
  margin-top: 0.3em;
  margin-bottom: 1em;
  white-space: pre-wrap;
}

input,
.Select-input {
  font-weight: 500;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px var(--color-input-background) inset;
}

input::-webkit-input-placeholder { /* WebKit, Blink, Edge */
  color: var(--color-input-placeholder) !important;
  filter: grayscale(100%); /* Necessario para o ícone colorido nos campos de filtro */
  opacity: 0.3;
  font-weight: lighter;
}

.Select-placeholder {
  color: var(--color-input-placeholder) !important;
  filter: grayscale(100%); /* Necessario para o ícone colorido nos campos de filtro */
  opacity: 0.3;
  font-weight: lighter;
}

input:-moz { /* Mozilla Firefox */
  color: var(--color-input-placeholder);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: grayscale(1);
  filter: grayscale(100%);
  filter: gray; /* Nenhum filtro funcionou! */
  opacity: 0.3;
  font-weight: lighter;
}

::-moz { /* Mozilla Firefox 19+ */
  color: var(--color-input-placeholder);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: grayscale(1);
  filter: grayscale(100%);
  filter: gray; /* Nenhum filtro funcionou! */
  opacity: 0.3;
  font-weight: lighter;
}

:-ms-input { /* Internet Explorer 10-11 */
  color: var(--color-input-placeholder);
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: grayscale(1);
  filter: grayscale(100%);
  filter: gray; /* Nenhum filtro funcionou! */
  opacity: 0.3;
  font-weight: lighter;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--color-input-placeholder);
  filter: gray;
  opacity: 0.3;
  font-weight: lighter;
}

.Select-control { /* Substitui estilização do React Select */
  background: var(--color-input-background) !important;
  color: var(--color-input-text) !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: var(--default-border-radius-input) !important;
  border: var(--input-border) !important;
}

.Select-input:focus {    
  outline: 2px solid var(--color-outline) !important;
}

.Select-placeholder  {
  padding-left: 5px !important;
}

.Select-menu-outer {    
  border: var(--input-border) !important;
  border-radius: var(--default-border-radius-input) !important;

  border-top-color: var(--color-input-background) !important;
  margin-top: 1px;
}

.Select-menu-outer > * {
  border-radius: var(--default-border-radius-input) !important;
}

.Select-menu-outer, .Select-option {
  background-color: var(--color-input-background) !important;
  color: var(--color-input-text) !important;
}

.Select-option.is-focused {
  background: var(--color-white-transparent) !important;
}

.Select-option.is-selected {
  background: var(--color-black-transparent) !important;
}

.Select.is-open {
  z-index: 100 !important;
}

/* Input de quantidade de produto com botões '-' e '+' */
.input-group {
  background-color: var(--color-card-accordion-button);
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: calc(15px + 0.5vw);
  text-align: center;
  vertical-align: center;
}
  
.input-group.pagamento {    
  font-size: calc(10px + 0.5vw);
}

.input-group button {
  background-color: var(--color-black-transparent);
  font-weight: bold;
  padding: 0;
}
  
.input-group button .quantidade {
  background: var(--color-card-accordion-button) !important;
  background-color: var(--color-card-accordion-button) !important;
}

.input-group .quantidade {
  cursor: default; /* Para não aparecer cursor de edição de texto */
}

/* Checkbox de campos de formulário
 .checkbox, */
.radio-form-cell {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 2px;
}

@media (pointer: fine) {
  .radio-form-cell input[type='radio'] {
    transform: scale(1.5);
    margin-left: 0.25em;
  }
}

.radio-form-cell input[type='radio'] + label {
  appearance: none;
  background-color: var(--color-input-background);
  border: 1px solid var(--color-input-chekbox-border);
  box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
}

.radio-form-cell input[type='radio'] + label {
  border-radius: 50%;
}

.radio-form-cell input[type='radio'] + label:active,
.radio-form-cell input[type='radio']:checked + label:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.radio-form-cell input[type='radio']:checked + label {
	background-color: #e9ecee;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #99a1a7;
}

.radio-form-cell input[type='radio']:checked + label:after {
  font-family: 'Segoe UI Symbol'; /* Necessário para o check ser correto no IE */
	content: '\2714';
	font-size: 14px;
	position: absolute;
  top: -2px;
	left: 2px;
	color: var(--color-input-text);
}

.radio-form-cell input[type='radio']:checked + label:after {
  content: '\23FA';
  top: -2.2px;
	left: 2.1px;
}

.radio-form-cell input[type='radio']:disabled + label {
  pointer-events: none;
  opacity: .8;
}

.radio-form-cell > div {
  margin-left: 5px;
}

/* Não juntar seletores. O placeholder é implementado de maneira diferente por cada navegador.
 O seletor dos outros navegadores é considerado inválido, ao invés de não encontrado.
 Um seletor inválido em uma lista de seletores invalida toda a lista.  */
input[type=text].filter::-webkit-input-placeholder { /* for webkit */
  color: var(--color-input-text);
  opacity: 1;
}

input[type=text].filter:-moz-placeholder { /* for mozilla  */
  color: var(--color-input-text);
  opacity: 1;
}

input[type=text].filter:-ms-input-placeholder { /* for internet explorer */
  color: var(--color-input-text);
  opacity: 1;
}

.slidecontainer {
  width: 100%;
}

/* The slider itself */
.slidecontainer > input[type=range] {
  /* variables */  
  -webkit-appearance: none;
  appearance:         none;
  width:              calc(100% - 40px);
  height:             3px;
  background:         var(--color-light);
  outline:            none;
  opacity:            0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

/* Mouse-over effects */
.slidecontainer > input[type=range]:hover {
  opacity: 1;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slidecontainer > input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance:         none;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slidecontainer > input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance:         none;
  width:              15px;
  height:             15px;
  background:         var(--color-light);
  border:             var(--default-border);
  border-radius:      50%;
  cursor:             pointer;
}

.slidecontainer > input[type=range]::-moz-range-thumb {
  width:              15px;
  height:             15px;
  background:         var(--color-light);
  border:             var(--default-border);
  border-radius:      50%;
  cursor:             pointer;
}
