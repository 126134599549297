input.ui-entrada,
input.ui-entrada[type=text],
input.ui-entrada[type=number],
input.ui-entrada[type=email],
input.ui-entrada[type=password],
input.ui-entrada[type=numeric] {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;

  width: calc(100% - 1.6em);
  height: auto;
  max-height: 2em;

  box-sizing: unset;
  
  border: var(--entrada-borda);
  border-radius: var(--entrada-borda-raio);
  
  padding: 0.5em 0.7em;
  
  margin: 0.1em 0 0.7em 0;

  background: var(--cor-da-entrada);
  
  font-size: 0.9em;
  color: var(--cor-da-entrada-texto);
}

input.ui-entrada::-webkit-input-placeholder,
input.ui-entrada[type=text]::-webkit-input-placeholder,
input.ui-entrada[type=number]::-webkit-input-placeholder,
input.ui-entrada[type=email]::-webkit-input-placeholder,
input.ui-entrada[type=password]::-webkit-input-placeholder,
input.ui-entrada[type=numeric]::-webkit-input-placeholder {
  color: var(--cor-da-entrada-placeholder) !important;
  font-size: 0.9em !important;
  font-weight: 100 !important;
  opacity: 0.8 !important;
}

input.ui-entrada:-moz-input-placeholder,
input.ui-entrada[type=text]:-moz-input-placeholder,
input.ui-entrada[type=number]:-moz-input-placeholder,
input.ui-entrada[type=email]:-moz-input-placeholder,
input.ui-entrada[type=password]:-moz-input-placeholder,
input.ui-entrada[type=numeric]:-moz-input-placeholder {
  color: var(--cor-da-entrada-placeholder) !important;
  font-size: 0.9em !important;
  font-weight: 100 !important;
  opacity: 0.8 !important;
}

input.ui-entrada::-moz-input-placeholder,
input.ui-entrada[type=text]::-moz-input-placeholder,
input.ui-entrada[type=number]::-moz-input-placeholder,
input.ui-entrada[type=email]::-moz-input-placeholder,
input.ui-entrada[type=password]::-moz-input-placeholder,
input.ui-entrada[type=numeric]::-moz-input-placeholder {
  color: var(--cor-da-entrada-placeholder) !important;
  font-size: 0.9em !important;
  font-weight: 100 !important;
  opacity: 0.8 !important;
}

input.ui-entrada:-ms-input-placeholder,
input.ui-entrada[type=text]:-ms-input-placeholder,
input.ui-entrada[type=number]:-ms-input-placeholder,
input.ui-entrada[type=email]:-ms-input-placeholder,
input.ui-entrada[type=password]:-ms-input-placeholder,
input.ui-entrada[type=numeric]:-ms-input-placeholder {
  color: var(--cor-da-entrada-placeholder) !important;
  font-size: 0.9em !important;
  font-weight: 100 !important;
  opacity: 0.8 !important;
}

input.ui-entrada::-ms-input-placeholder,
input.ui-entrada[type=text]::-ms-input-placeholder,
input.ui-entrada[type=number]::-ms-input-placeholder,
input.ui-entrada[type=email]::-ms-input-placeholder,
input.ui-entrada[type=password]::-ms-input-placeholder,
input.ui-entrada[type=numeric]::-ms-input-placeholder {
  color: var(--cor-da-entrada-placeholder) !important;
  font-size: 0.9em !important;
  font-weight: 100 !important;
  opacity: 0.8 !important;
}

input.ui-entrada::placeholder,
input.ui-entrada[type=text]::placeholder,
input.ui-entrada[type=number]::placeholder,
input.ui-entrada[type=email]::placeholder,
input.ui-entrada[type=password]::placeholder,
input.ui-entrada[type=numeric]::placeholder {
  color: var(--cor-da-entrada-placeholder) !important;
  font-size: 0.9em !important;
  font-weight: 100 !important;
  opacity: 0.8 !important;
}

.ui-entrada-label {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: auto;
}
