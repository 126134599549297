.ItemStateAlert, .SaleSourceAlert {
  max-width: 28px;
  min-width: 25px;
  max-height: 28px;
  min-height: 25px;

  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 40px;
  text-align: center;

  /* Animação gerada da seguinte maneira:
   Precisa de um frame final, na posição 100%, para reiniciar a animação.
   Feito para que a distância entre os frames seja cada vez maior, para simular um pêndulo desacelerando.
   Usada a equação que descreve um círculo passando por 3 pontos para gerar as posições dos frames:

   ((y-y1)*(y-y2)+(x-x1)*(x-x2))/((x-x2)*(y-y1)-(x-x1)*(y-y2))=((y3-y1)*(y3-y2)+(x3-x1)*(x3-x2))/((x3-x2)*(y3-y1)-(x3-x1)*(y3-y2))

   Os pontos mínimo e máximo são (0,0) e (100,100), para gerar na escala correta.
   O ponto do meio deve ser (x,100-x) para gerar a curva simétrica. Foi escolhido (55,45). (50,50) gera uma linha reta e divisão por zero.
   Feita a curva, foram gerados valores equidistantes entre 0 e 100 para ser o X.
   Aplicando o X na curva, o Y resultante são as posições dos frames. */
  animation: all 3s linear infinite;
}

.ItemStateAlert.ie,
.SaleSourceAlert.ie {
  width: 30px;
  height: 30px;
}

.ItemStateAlert div,
.SaleSourceAlert div {
  width: 90%;
  height: 3px;
}

.ItemStateAlert {
  margin-left: 5px;
  margin-right: 3px;
}

.SaleSourceAlert {
  margin-left: 3px;
}
