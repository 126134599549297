.Header {
  height: var(--size-headertop);
  width: 100%;
  position: fixed;
  background: linear-gradient(to right,rgb(3,73,133),rgb(3,73,133),rgb(3,73,133));
  color: var(--color-headertop-text);    
  font-size: var(--size-headertop-text);
  padding: 0 calc(5px + 1vh);
  display: flex;
  box-sizing: border-box;
  z-index: 18;
  flex-shrink: 0;
  font-size: var(--size-headertop-text);
  min-width: 240px;
}

.Header * {
  background-color: transparent;
}

.Header nav {
  height: 100%;
}

.Header > * {
  -ms-grid-row-align: center;
          align-self: center;
}

.Header.ie {
  min-width: 360px;
}

.DrawerToggle:hover,
.DrawerToggle:focus {
  border: 2px solid #99f;
}

.Logo {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: center;

  width: 100%;
}

.Logo button {
  border-radius: 2px;
  max-width: 200px;
  min-width: 150px;
  height: 40px;
  border: none;
}

.Logo button h1 {
  font-size: calc(16px + 0.7vw);
}

.Logo button svg {
  width: inherit !important;
  height: inherit !important;
}

.Logo button:hover {
  opacity: 0.7;
}

.helpToggle {
  position: inline-block;
  max-width: 40px;
  min-width: 35px;
  font-size: 30px;
  border: none;
  line-height: 0px;
  text-align: center;
  margin-right: 2px;
}

.helpToggle:hover {
  opacity: 0.7;
}

.Header .Select-control {
  height: auto !important;
}

.Header .Select-value {
  padding: 0 !important;
}

.Header .Select-option {
  padding: 3px 2.8px;
}

.languageOptionClass {
  display: flex;
  align-items: center;
  top: -2px !important; /* precisa porque o .Select-option do React.Select vem depois por algum motivo */
}

.languageSelect {
  border: none;
  width: 38px;
  position: inline-block;
  margin-left: 10px;
  border: 2px solid transparent !important;
}

.languageSelect .Select-menu-outer {
  border: none !important;
  width: 40px;
  background: transparent !important;
}

.languageSelect .Select-control {
  width: 38px;
  border: 2px solid transparent !important;
  background-color: transparent !important;
}

.languageSelect .Select-control .Select-input:focus {
  background: inherit;
}

.languageSelect:hover .Select-control,
.languageSelect:focus .Select-control  {
  width: 38px;
  opacity: 0.7;
}

#helpToggle {
  display: none;
}

label.verticalCenterInputAndSVG,
.verticalCenterTextAndSVG .verticalCenterInputAndSVG {
  display: flex;
}

@media screen and (max-width:568px) {
  .languageSelect {
    margin-left: 0px;
    width: 60px !important;
  }

  .helpToggle {
    padding: 0px;
  }

  .Logo {
    margin-left: 0px;
  }
    
  .Logo button {
    margin: 0px;
    padding: 0px;
  }
}