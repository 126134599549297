.productionHelp {
  padding: 5px;
}

.producao-header {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: wrap;
}

.producao-header .mostrarEncerrados,
.producao-header .agruparProdutosVenda {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  padding-top: 1.2em;
}

.producao-header .resumoEntregador {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.producao-header .resumoEntregador .quantidadeEntregas {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  max-width: 10em;
}

.producao-header .resumoEntregador .valorTeles {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  max-width: 10em;
}

.producao-header .temposAviso {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.producao-header .temposAviso .tempoVermelho {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  max-width: 8em;
}

.producao-header .temposAviso .tempoAmarelo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  max-width: 8em;
}

@media screen and (max-width: 1120px) {
  .producao-header .resumoEntregador,
  .producao-header .temposAviso {
      flex-grow: 0;
  }
}

.producao-ordenacao {
  display: flex;
  flex-wrap: wrap;

  margin: 0.25em 0.75em 1.25em 0.75em;
}

.producao-ordenacao .Select-input:focus {
  outline: none !important;
}

.producao-ordenacao .Select-control .Select-input:focus {
  background: inherit;
}