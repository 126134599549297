.botao-carrinho {
  background: white;
  
  color: var(--cor-texto);
}

.botao-carrinho > .ui-icone {
  width: 2.5em; 
  height: 2.5em; 
}

.botao-carrinho > .quantidade {
  position: absolute;
  top: 0.1em;
  right: 0.5em;

  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border-radius: 0.2em;
  width: 1.4em;
  height: 1.4em;
  background-color: var(--botao-adicionar-ao-carrinho-cor);
  color: white;
}

.botao-carrinho.unidade {
  background: var(--botao-carrinho-mais);
  color: white;
}

.botao-carrinho:disabled {
  display: flex;
  background: white;
  color: var(--botao-cor-texto-desabilitado);
}