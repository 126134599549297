.pedido-card {
  display: flex !important;
  flex-flow: column nowrap !important;
  flex: 0 1 auto !important;
  
  width: 100% !important;
  height: auto !important;
  
  border-radius: var(--card-borda-raio) !important;

  margin-top: 0 !important;
  margin-bottom: 0.5em !important;

  padding-top: 0.3em;
  padding-bottom: 0.3em;

  background: var(--cor-principal) !important;

  color: var(--cor-principal-texto) !important;

  -webkit-tap-highlight-color: transparent;

  font-family: 'Roboto';
}


.pedido-card > div {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  margin: 0;
}

.pedido-card .linha {
  cursor: pointer;
}

.pedido-card .linha > div {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  margin: 0.2em 0.5em;
}

.pedido-card .linha > div > .campo {
  margin-right: 0.25em;
  font-weight: 500;
}

.pedido-card .linha > div > .valor {
  font-weight: 400;
  font-size: 0.9em;
  margin-top: 2px;
}

.pedido-card .linha > .valor-total {
  justify-content: flex-end;
}

.pedido-card .linha > .numero-de-itens {
  justify-content: center;
}

.pedido-card .linha > .flecha {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;

  width: auto;
  height: auto;
}

.pedido-card .linha > .flecha > .ui-icone {
  height: 1.6em !important;
  color: var(--cor-principal-texto);
}

.pedido-card .linha > .motivo-estado-pedido {
  font-size: 0.8em;
  font-weight: 400;
  margin-top: 0;
  margin-left: 1.4em;
}

.pedido-card > .produtos {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  min-width: 0;
  margin: 0.7em 0.5em 0.2em 0.5em;
  padding: 0;
  background: var(--cor-terciaria);
  color: var(--cor-terciaria-texto);
  font-size: 0.7em;
}

.pedido-card > .produtos > .produtos-cabecalho {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #ced4da;
  color: #000;
  font-weight: 900;
  font-size: 0.9em;
  min-height: 2.5em;
}

.pedido-card > .produtos > .produtos-cabecalho > .coluna {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  text-align: center;
  padding: 0 0.2em;
}

.pedido-card > .produtos > .produtos-itens {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pedido-card > .produtos > .produtos-itens > .linha-item {
  display: flex;
  flex-direction: row;
}

.pedido-card > .produtos > .produtos-itens > .linha-item:last-child {
  border-bottom: 0.5px rgb(206, 212, 218) var(--borda-estilo);
}

.pedido-card > .produtos > .produtos-itens > .linha-item > .item {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 0.4em 0.2em;
}

.pedido-card > .produtos .coluna,
.pedido-card > .produtos .item {
  border-right: 0.5px rgb(206, 212, 218) var(--borda-estilo);
}

.pedido-card > .produtos .coluna.nome,
.pedido-card > .produtos .item.nome {
  border-left: 0.5px rgb(206, 212, 218) var(--borda-estilo);
}

.pedido-card > .produtos .nome,
.pedido-card > .produtos .observacoes {
  align-items: flex-start;
  white-space: normal;
  width: 24%;
}

.pedido-card > .produtos .quantidade {
  align-items: center;
  width: 12%;
}

.pedido-card > .produtos .preco-unitario,
.pedido-card > .produtos .valor-pago {
  align-items: flex-end;
  text-align: right;
  width: 20%;
}

@media screen and (min-width: 660px) {
  .pedido-card > .produtos .quantidade {
    width: 6%;
  }

  .pedido-card > .produtos .preco-unitario,
  .pedido-card > .produtos .valor-pago {
    width: 10%;
  }
}

@media screen and (min-width: 768px) {
  .pedido-card > .produtos {
    font-size: 0.8em;
  }
  
  .pedido-card > .produtos > .produtos-cabecalho > .coluna {
    padding: 0 0.4em;
  }

  .pedido-card > .produtos > .produtos-itens > .linha-item > .item {
    padding: 0.5em 0.4em;
  }
}

@media screen and (min-width: 960px) {
  .pedido-card > .produtos .quantidade {
    width: 3%;
  }

  .pedido-card > .produtos .preco-unitario,
  .pedido-card > .produtos .valor-pago {
    width: 5%;
  }
}