.ui-icone {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
}
  
.ui-icone > image {
  width: 100%;
  height: 100%;
}
