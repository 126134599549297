#compra-tela {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  max-width: 50em;

  font-family: 'Roboto';
  font-size: 1.1em;
}

#compra-tela > .ui-texto {
  padding: 1em 0;
}

#compra-tela > div {
  margin-bottom: 0.5em;
  font-weight: 500;

  width: calc(100% - 1em*2);
  height: 100%;

  border: var(--borda);
  border-radius: 0.3em;

  padding: 0.5em 1em;
}

#compra-tela > #data-hora-entrega > .ui-texto {
  text-align: left;
  padding: 0.2em 0;
}

#compra-tela > #data-hora-entrega > .ui-entrada {
  font-family: 'Arial';
  font-weight: 400;
  max-height: 1.24em;
}

#compra-tela > #endereco-usuario div {
  display: flex;
  align-items: flex-start;
}

#compra-tela > #endereco-usuario .ui-texto {
  text-align: left;
  padding: 0.2em 0;
}

#compra-tela > #endereco-usuario > .linha {
  flex-wrap: wrap;
  flex-grow: 1;
}

#compra-tela > #endereco-usuario > .linha > .linha-item {
  flex-wrap: nowrap;
  width: 50%;
  min-width: fit-content;
}

#compra-tela > #endereco-usuario > .linha > .linha-item > .campo {
  margin-right: 0.2em;
  font-size: 1em;
  font-weight: 500;
}

#compra-tela > #endereco-usuario > .linha > .linha-item > .valor {
  font-size: 0.9em;
  font-weight: 400;
  margin-right: 1em;
  margin-top: 2px;
}

#compra-tela > #forma-pagamento > .forma-pagamento-totais {
  display: flex;
  margin-top: 0.5em;
  font-weight: 500;
}

#compra-tela > #forma-pagamento > .forma-pagamento-totais > .campo {
  margin-right: 0.2em;
  font-size: 1em;
  font-weight: 500;
}

#compra-tela > #forma-pagamento > .forma-pagamento-totais > .valor {
  color: var(--cor-preco);
  font-size: 1em;
  font-weight: 500;
}

#compra-tela #observacao-geral .ui-entrada {
  font-family: 'Arial';
  font-weight: 400;
}