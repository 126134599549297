#dialog-quadro-horarios-edicao {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
}
  
#dialog-quadro-horarios-edicao > .dias-da-semana {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  justify-content: center;
}

#dialog-quadro-horarios-edicao > .dias-da-semana .checkbox-form-cell {
  width: 5em;
  margin: 0.5em 0.3em;
}

#dialog-quadro-horarios-edicao > .dia-da-semana-selecionado {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  justify-content: center;
  font-size: 1.3em;
  font-weight: 500;
}

#dialog-quadro-horarios-edicao > .horarios {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

#dialog-quadro-horarios-edicao > .horarios > .horarios-item {
  display: flex;
  align-items: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

#dialog-quadro-horarios-edicao > .horarios > .horarios-item > span {
  width: 2.2em;
  margin-right: 0.5em;
  text-align: end;
}

#dialog-quadro-horarios-edicao > .aviso {
  display: flex;
  height: 2em;
  margin-top: 1em;
  font-weight: 700;
  font-size: 1.2em;
  color: red;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#dialog-quadro-horarios-edicao > .botoes {
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 1em;
}

#dialog-quadro-horarios-edicao > .botoes .button-primary {
  background: var(--color-secondary);
  color: white;
  width: 15em;
  min-height: 2.5em;
  font-weight: 400;
  margin: 0 0.8em;
}

#dialog-quadro-horarios-edicao > .botoes .button-primary:disabled {
  background: var(--color-mid);
}

#dialog-quadro-horarios-edicao > .botoes .button-primary:focus, #dialog-quadro-horarios-edicao > .botoes .button-primary:hover {
  background: var(--color-primary);
}