.from-produced-item {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  
  min-width: 30em;

  padding: 0.4em;

  border-radius: var(--default-border-radius-button);

  margin: 3px 20px 10px 1.5px !important;
}

.from-produced-item > .linha {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;

  padding: 0.35em 0;
  text-align: center;
}

.from-produced-item > .linha > .coluna {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  white-space: nowrap;

  font-weight: 500;
  font-size: 1.3em;
  color: white;
}

.from-produced-item > .linha > .coluna.LeftAlign {
  align-items: flex-start;
  justify-content: center;
}

.from-produced-item > .linha > .coluna.CenterAlign {
  align-items: center;
  justify-content: center;
}

.from-produced-item > .linha > .coluna.RightAlign {
  align-items: flex-end;
  justify-content: center;
}

.from-produced-item > .linha > .coluna.nome-venda {
  font-size: 1.15em;
}

.from-produced-item > .linha .items,
.from-produced-item > .linha .items > .coluna {
  background: white;
  background-color: #F0F1F2;
  
  color: rgba(0, 0 , 0, 0.8) !important;
  font-weight: 500;
  font-size: 1.1em;
  border-radius: var(--default-border-radius-card);
}
