.Loader {
  width: 100%;
  height: 100%;
  margin-left: calc((-54px - 10vw)/2); /* Centralizar */
  margin-top: calc((-54px - 10vw)/2); /* Centralizar */
  border: calc(4px + 1vw) solid var(--color-spinner-borda);
}

.LoaderInput {
  width: 12px;
  height: 12px;
  border: 4px solid var(--color-spinner-borda);
}

.Loader,
.LoaderInput {
  position: absolute;
  border-radius: 50%;
  border-top-color: var(--color-spinner-spin);
  animation: spinner .7s linear infinite;
}

@keyframes spinner {
  to {transform: rotate(360deg);}
}