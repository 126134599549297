#botao-voltar {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  width: 6em;
  height: 2.5em;

  border-color: var(--borda-cor);

  background: var(--cor-botao-voltar);

  color: var(--cor-botao-voltar-texto);

  box-shadow: var(--borda-cor) 0.05em 0.05em;

  font-family: 'Roboto';
  font-weight: 400;
}

#botao-voltar:disabled {
  display: none;
}

#botao-voltar > svg {
  width: 1.8em;
  height: 1.8em;
}