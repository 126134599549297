.skylight-overlay {
	z-index: 1000001 !important;
	animation: fadein .3s linear !important;
}

.spinner-system .skylight-overlay {
	z-index: 1000003 !important;
}

.notification-system .skylight-overlay {
	z-index: 1000005 !important;
}

.skylight-dialog {
	top: 10% !important;
	left: 50% !important;
	margin-top: 0 !important;
	border-radius: 0 !important;
	z-index: 1000002 !important;
	padding: 10px !important;
	min-height: 0% !important;
	max-height: 85% !important;
	max-width: 90% !important;
	overflow: auto;
  background-color: var(--color-dialog-background) !important;
}
  
.skylight-dialog#Spinner {
  z-index: 1000004 !important;
  top: 35% !important;
  width: calc(30px + 8vw) !important;
  height: calc(30px + 8vw) !important;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-right: auto !important;
  background: none !important;
  box-shadow: none !important;
  overflow: inherit;
}

@media screen and (min-width: 768px) {
  .skylight-dialog#Notification {
    width: 30% !important;
    margin-left: -16.25% !important;
  }

  .skylight-dialog#Dialog {
    width: 45% !important;
    margin-left: -23.25% !important;
  }

  .skylight-dialog#Spinner {
    left: 44.5%;
  }
}

/* Aumenta os dialogs no mobile */
@media screen and (max-width: 768px) {
  .skylight-dialog {
    height: auto !important;
    width: 90% !important;
    margin-left: -47.5% !important;
  }

  .skylight-dialog#Spinner {
    left: 37%;
  }
}


.notification-system .skylight-dialog {
	z-index: 1000006 !important;
}

/* Dialog title */
.skylight-dialog > h2 {
  padding: 1px;
  margin: 0 0 15px 0;
  color: var(--color-dialog-text);
  border-bottom: 1px solid var(--color-black-transparent);
}

.skylight-dialog .dialog-content {
  margin: auto;
  width: 85%;
  height: 90%;
  overflow: auto;
}

.skylight-dialog .dialog-horizontal-button-grid {
  padding: 15px 0 5px 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  -ms-grid-columns: 1fr 10px auto 10px auto;
  grid-template: "nada btn1 btn2" auto / 1fr auto auto;
  grid-gap: 10px;
}
  
.skylight-dialog .dialog-horizontal-button-grid.flow-buttons {
  display: flex;
  padding: 15px 0 5px 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.skylight-dialog .dialog-horizontal-button-grid.flow-buttons button {
  margin: 5px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 1px;
  flex: 1 1 1px;
  min-width: 10em;
}

.skylight-dialog .dialog-horizontal-button-grid #yes-button {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  grid-column-start: 2;
  grid-area: btn1;
}

.skylight-dialog .dialog-horizontal-button-grid #no-button {
  -ms-grid-row: 1;
  -ms-grid-column: 5;
  grid-area: btn2;
}

.skylight-dialog .dialog-vertical-button-grid {
  padding: 15px 0;
  display: block;
}

.skylight-dialog .dialog-horizontal-button-grid button,
.dialog-vertical-button-grid button {
  font-size: 17px;
  padding: 0 10px;
  background: none;
  text-transform: uppercase !important;
  color: var(--color-text-link);
  font-weight: bold !important;
}

.skylight-dialog .dialog-horizontal-button-grid button:disabled,
.dialog-vertical-button-grid button:disabled {
  color: var(--color-text-dark) !important;
  opacity: 0.2;
}

/* Botão de "Livre" no dialog de input do peso de produto */
.skylight-dialog .dialog-horizontal-button-grid button.livre {
  color: var(--color-text-dark) !important;
  opacity: 0.5;
}

.skylight-dialog .dialog-scroll-pane {
  overflow: auto;
}

.skylight-dialog .dialog-scroll-pane .table {
  background: var(--color-dialog-background);
}

.unique {
	padding: 15px 0 5px 0;
	display: inline-block !important;
	width: 100%;
	grid-template-areas: none;
	grid-template-columns: none;
	grid-template-rows: none;
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
