:root {
  --scrollbar-size: 0px;
  --menu-size: var(--size-leftdrawer);
}

.SideDrawer {
  position: fixed;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 16;

  display: flex;
  flex-direction: row-reverse;
  transition: all 0.2s ease-in-out;
}

.Open {
  transform: translateX(0);
  width: 100%;
}

.Close {
  transform: translateX(-100%);
  width: 0;
}

#SideDrawer-placeholder {
  position: fixed;
  width: var(--menu-size);
  height: 100%;
  pointer-events: none;
}

/* Por enquanto, não é possível usar variáveis em media queries, mas essa funcionalidade está em desenvolvimento (2018/04/27).
 Se precisar alterar esse valor, alterar também em Layout.js.
 SideDrawer ocupa tela enteira quando a tela tiver esse valor. */
@media (min-width: 768px) {
  .menu-opened {
    padding-left: var(--menu-size);
  }

  .menu-closed {
    padding-left: 0px;
  }

  .SideDrawer {
    width: var(--menu-size);
  }
}

@media (max-width: 768px) {
  :root {
    --menu-size: 100%;
  }

  .SideDrawer {
    width: 100% !important;
  }
}

.pure-menu {
  flex-grow: 1;
  height: 100%;
}

.SideDrawerCompanyNameWrapper {
  position: sticky;
  z-index: 17;
  top: 0;
  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: center; /* Vertical center alignment */
  justify-content: center; /* Horizontal center alignment */
  overflow: hidden;

  width: var(--size-headerleft);
  background: var(--color-headerleft-background);
}

.SideDrawerCompanyNameWrapper.ancient-chrome-mobile {
  position: fixed;
  padding-top: calc(40px - 1vh);
}

.SideDrawerCompanyNameWrapper > .SideDrawerCompanyName {
  /* https://stackoverflow.com/questions/9249359/is-it-possible-to-vertically-align-text-within-a-div */

  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;

  transform: rotate(-90deg);
  white-space: nowrap;
  font-size: var(--size-headerleft-text);
  
  color: var(--color-headerleft-text);
}

.userNameVersionNumberDiv {
  position: absolute;
  display: flex;
  left: calc(0.5em + var(--size-headerleft));
  right: 0.5em;
  bottom: 0.5em;
  z-index: -1;
}

.userNameVersionNumberDiv .userName {
  flex: 1 0 0;
  overflow: hidden;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
}

.userNameVersionNumberDiv .versionNumber {
  flex: 1 0 1;
}

.menuItem:hover,
.menuItem:focus {
  background-color: var(--color-gray-button-focused) !important;
  color: var(--color-text-button-focused) !important;
}

.menuItem.active:hover,
.menuItem.active:focus {
  background-color: var(--color-primary) !important;
}

.superMenuItem:focus,
.superMenuItem:hover {
  background-color: var(--color-gray-button-focused) !important;
  color: var(--color-text-button-focused) !important;
}

.accordion-menu {
  text-align: left;
}
