:root, body { 
	padding: 0;
	margin: 0;

	width: 100%;

	background: var(--cor-de-fundo);
}

:root, body, #root {
	height: 100%;
}

body.no-scroll {
	overflow: hidden;
}

#root {
	margin: 0px;
	display: flex;
	box-sizing: border-box;
}

.column {
	padding-top: var(--size-headertop);
	display: flex;
	flex-direction: column;
}

.bottom {
	flex-grow: 1; /* ensures that the container will take up the full height of the parent container */
}

#right {
	width: 100%;
}

.Content {
	margin: 1vw;
	
	transition: all 0.2s ease-out;
}

.Content a {
	color: inherit;
	text-decoration: inherit;
}

.warning-template {
	color: var(--color-warning);
	font-size: larger;
	font-weight: bolder;
}

.hidden {
	display: hidden;
}

.action-bar {
	display: flex;
	flex-direction: row;
}

.action-bar > * {
	width: 100%;
	margin: 0 0.1em;
}

.spaced-horizontal-content > * {
	margin: 0 5px 0 0;
}

.info-text {
	color: var(--color-mid);
	font-size: xx-large;
	font-weight: bold;
}

.line-separator {
	border-top: thin;
	border-top-style: solid;
	border-top-color: var(--color-dark-button);
	
	border-bottom: thin;
	border-bottom-style: solid;
	border-bottom-color: var(--color-dark-button);
}

.centralize-content {
	text-align: center;
	justify-content: center;
}

.fit-content {
	width: fit-content;
	height: fit-content;
}

.fit-content.width {
	height: auto;
}

.fit-content.height {
	width: auto;
}

.alinhar-a-esquerda {
	display: flex;
	align-items: left;
	justify-content: left;
}

.alinhar-ao-centro {
	display: flex;
	align-items: center;
	justify-content: center;
}

.alinhar-a-direita {
	display: flex;
	align-items: right;
	justify-content: right;
}

.coluna {
	display: flex;
	flex: 0 1 auto;
	flex-flow: column nowrap;
}

.coluna > * {
	margin: 0.2em 0;
}

.mobi-button {
	width: auto;
	height: auto;
	padding: 0.3em 0.7em;
	margin: 0.2em;
	border-radius: 0.2em;
	text-align: center;
	font-weight: bolder;
	font-size: var(--size-home-button-text);
}

.mobi-button.primary-style {
	background: var(--color-primary);
	color: white;
}

.mobi-button.secondary-style {
	background: var(--color-secondary);
	color: white;

}

.mobi-button.secondary-style:hover {
	background: var(--color-primary);
	color: white;
}

.mobi-button.normal-style {
	background: #f6f5f4;
	color: "black";
}

.mobi-button.normal-style:hover {
	background: #777;
	color: white;
}

.button-grid {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;
}

.tour > div > nav {
	min-width: 4em;
	display: flex;
	flex-flow: row wrap;
}

.tour-mask {
	color: rgba(0,0,0,0.3) !important;
}

@keyframes fadeindown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes fadeinup {
	0% {
		opacity: 0;
		transform: translateY(20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0px);
	}
}

@keyframes slidedown {
	0% {
		transform: translateY(-20px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes slideup {
	0% {
		transform: translateY(20px);
	}

	100% {
		transform: translateY(0px);
	}
}

@keyframes scaletoappear {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes scaletohide {
	0% {
		transform: scale(0);
	}

	100% {
		transform: scale(1);
	}
}

.fadeinup {
	animation: fadeinup 1s;
}

.fadeindown {
	animation: fadeindown 1s;
}

.slideup {
	animation: slideup 1s;
}

.slidedown {
	animation: slidedown 1s;
}

.scaletoappear {
	animation: scaletoappear 1s;
}

.scaletohide {
	transition: scaletohide 1s;
}

@media screen and (min-width:48em) {
	.pure-u-sideDrawerShow.pure-u-sm-1 {
		width: 100%;
	}
}

@media screen and (min-width:64em) {
	.pure-u-sideDrawerShow.pure-u-md-1-2 {
	  	width: 50%;
	}
}

@media screen and (min-width:78em) {
	.pure-u-sideDrawerShow.pure-u-lg-1-3 {
		width: 33.33%;
	}
}

@media screen and (min-width:96em) {
	.pure-u-sideDrawerShow.pure-u-xl-1-3 {
		width: 33.33%;
	}
}

@media screen and (min-width:110em) {
	.pure-u-sideDrawerShow.pure-u-xxl-1-4 {
		width: 25%;
	}
}

@media screen and (min-width: 48em) {
	.show-if-mobile {
		display: none !important;
	}
}

@media screen and (max-width: 48em) {
	.hide-if-mobile {
		display: none !important;
	}
}
.app-card {
  overflow: hidden !important;
  min-width: 16em;
}

:root, body, #root {
  height: 100%;
}

body, .pure-g [class*="pure-u"],
.pure-g-r [class*="pure-u"], .pure-table *  {
  font-family: font-ampix-mobi, 'Open Sans', sans-serif;
  font-weight: 500;
}

:root {
  /* geral */
  --botao-cor: var(--cor-principal);
  --botao-borda: 1px solid #aaa;
  --botao-adicionar-ao-carrinho-cor: rgb(1, 153, 1);
  --botao-adicionar-ao-carrinho-borda: 1px solid rgb(1, 177, 1);
  --botao-carrinho-mais: rgb(1, 153, 1);
  --borda-cor: #aaa;
  --borda-tamanho: 1px;
  --borda-estilo: solid;
  --borda-cor: #aaa;
  --borda: var(--borda-tamanho) var(--borda-estilo) var(--borda-cor);
  --borda-raio: 1em;
  --cor-de-fundo: white;
  --cor-de-fundo-texto: rgb(50, 50, 50);
  --cor-do-dialog: white;
  --cor-do-dialog-texto: rgb(50, 50, 50);
  --cor-do-card: rgb(245, 245, 245);
  --cor-do-card-texto: rgb(50, 50, 50);
  --cor-texto: rgb(50, 50, 50);
  --cor-da-entrada: rgb(244, 244, 244);
  --cor-da-entrada-placeholder: #aaa;
  --cor-da-entrada-borda: rgb(206, 212, 218);
  /* Preco (App) */
  --cor-preco: rgb(0, 146, 69);

  /* botoes */
  --botao-borda-raio: 0.3em;
  --botao-cor-texto-desabilitado: #999;

  /* cards */
  --card-borda: 1px solid #aaa;
  --card-borda-raio: 0.3em;

  /* entradas */
  --entrada-borda: 1.5px solid var(--cor-da-entrada-borda);
  --entrada-borda-raio: 0.3em;

  /* dialogs */
  --dialog-borda-cor: #666;
  --dialog-borda: 1px solid var(--dialog-borda-cor);
  --dialog-borda-raio: 0.9em;
}

body {
  background: var(--cor-de-fundo);
}

select {
  overflow-y: scroll;
}

.app-card {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;

  background-image: none;
  background: var(--cor-do-card);

  margin: 1em 0;
  margin-top: 0;

  border: none;
  border-radius: var(--card-borda-raio);

  width: 100%;
  max-width: 50em;
  height: auto;

  color: var(--cor-do-card-texto);

  z-index: auto;
}

.app-card .linha-separadora {
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;

  margin: 0 5%;

  width: 90%;
  height: 1px;

  background: black;

  font-weight: lighter;
}

.tela > .barra {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  background: var(--cor-principal);
  color: var(--cor-principal-texto);
  border: var(--borda);
  border-color: var(--cor-principal);
  border-radius: 0.3em 0.3em 0 0;
  padding: 0.7em 0;
  width: 100%;
  margin-top: 1em;
}

.tela > #conteudo {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;

  width: calc(100% - 0.5em*2);
  height: calc(100% - 0.5em*2);
  margin: 0;
  padding: 0.5em 0.5em;
  border: var(--borda);
  border-top: none;
  border-radius: 0 0 0.3em 0.3em;
}

.row {
  display: flex;
  flex-grow: 0;
  flex-direction: row;
}

.column {
  display: flex;
  flex-grow: 0;
  flex-direction: column;
}

.column-left {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
}

.column-center {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}
  
.column-center > * {
  margin: 0.2em 0;
}

.column-right {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-end;
}

.texto-erro {
  color: red;
}

.button {
  cursor: pointer;

  font-weight: 500;

  padding: 0.2em;
}

.no-user-select {
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.no-mouse-cursor {
  cursor: none;
}

.color-primary {
  background: var(--color-primary);
}

.color-secondary {
  background: var(--color-secondary);
}

.text-color-white {
  color: white;
}

.text-color-black {
  color: black;
}

.height_2dot5em {
	height: 2.5em;
}

.width_5em {
	width: 5em;
}

.color-light-gray {
  background: var(--color-light);
}

.color-dark-gray {
  background: var(--color-dark);
}

.padding_2dot5px {
  padding: 2.5px;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-align-center {
  align-items: center;
}

.flex-justify-center {
  justify-content: center;
}

.half-width {
  width: 50%;
}

.full-width {
  width: 100%;
}