.observacao-dialog {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;

  align-items: center;
  justify-content: flex-start;
}

.observacao-dialog > div {
  width: 100%;
}

.observacao-dialog .ui-entrada {
  font-family: 'Roboto';
  font-weight: 400;
}