/* Para ocultar o separador até 767 pixels (48em - 1px) */
@media not screen and (width:48em) {
  @media screen and (max-width:48em) {
    .espaco-condicional {
        display: none;
    }
  }
}

/* Para ocultar o separador até 567 pixels (35.5em - 1px) */
@media not screen and (width:35.5em) {
  @media screen and (max-width:35.5em) {
    .espaco-condicional-sm {
        display: none;
    }
  }
}

.payment-controls {
  margin-top: 20px;
}

.observacao {
  width: 32px;
}