#login-dialog {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
}

#login-dialog * {
  font-family: 'Roboto';
  font-weight: 400;
}

#login-dialog .ui-entrada {
  font-size: 1em;
}

#login-dialog .ui-botao {
  width: 7em;
  height: 1.5em;
}

#login-dialog .ui-botao:disabled {
  display: flex !important;
  opacity: 0.8;
}


#login-dialog > .column-center > .nova-senha {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

#login-dialog > .column-center > .texto-erro {
  margin-top: 0.5em;
  font-weight: 500;
}

#login-dialog > .column-center > .cadastrar {
  border-radius: var(--botao-borda-raio);
  
  padding: 0.5em;
  
  background: var(--cor-principal);
  
  font-size: 0.9em;
  color: white;

  margin-top: 0.5em;
}

#login-dialog > .column-center > * {
  cursor: pointer;
}