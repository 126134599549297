.nivelUsuarioSemEmpresaSelect .Select-input:focus {
  outline: none !important;
}

.nivelUsuarioSemEmpresaSelect .Select-control .Select-input:focus {
 background: inherit;
}

.produtoPesadoPadraoSelect .Select-input:focus {
  outline: none !important;
}

.produtoPesadoPadraoSelect .Select-control .Select-input:focus {
 background: inherit;
}

.empresa-form fieldset input,
.empresa-form fieldset .Select {
  margin-bottom: 1em;
}