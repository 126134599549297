.homeButton {
  max-width: 100px;
  max-height: 100px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.homeHelp, .homeButton {
  margin-left: 10px; 
  margin-right: 10px; 
}
