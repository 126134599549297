.card {
  margin: 3px 1.5px 0px 1.5px; /* Sem margem embaixo para accordions que abrem do card não ficarem separados */
  color: var(--color-text-dark);
  background-color: var(--color-card-background);
  border-bottom: 4px solid var(--color-card-background);
  display: grid;
  grid-template: auto/auto;
  z-index: 1;
}

.card.with-footer {
  grid-template-rows: auto auto;
}

.card:hover {
  filter: var(--hover-filter);
}

.card h1,
.card h2,
.card h3 {
  margin: 0;
  padding: 2px;
  color: var(--color-text-light);
  font-weight: lighter;
}

.card h1 {
  font-size: calc(18px + 0.5vw);
  font-weight: normal;
}

.card h2 {
  font-size: calc(16px + 0.5vw);
}

.card h3 {
  font-size: calc(10px + 0.5vw);
}

.card-content {
  padding: 10px;
  font-size: 25px;
  user-select: none;
}

.adjust {
  width: 100%;
}

.card-footer {
  display: grid;
  margin: 2px 12px 0px 12px;
  align-items: center;
  text-align: center;
  font-size: calc(18px + 0.5vw);
}

/* Card do estado de produção */
.estado-producao > .card-content {
  display: grid;
  grid-template: 1fr auto / 8fr 1fr 1fr;
}

.estado-producao > .card-footer {
  display: grid;
  grid-template: 1fr / 1fr auto;
  vertical-align: center;
}

.estado-producao, .produced-item > .card-content > .card-cell {
  display: grid;
  -ms-grid-rows: 1fr 1fr auto;
  -ms-grid-columns: 1fr 1fr;
      grid-template: "text numb" 1fr / 1fr 1fr;
  grid-column: 1/4;
  text-overflow: initial;
  -ms-grid-row: 2;
}

.estado-producao .quantidade-item {
  grid-area: quantidade;
  grid-row: 1/2;
  grid-column: 3/4;
}

.estado-producao .codigo-origem {
  grid-area: codigo;
  grid-row: 1/2;
  grid-column: 1/2;
}

.estado-producao .nome-venda {
  grid-area: nome;
  grid-row: 1/2;
  grid-column: 2/3;
}

.estado-producao .nome-usuario {
  grid-area: usuario;
  grid-row: 2/3;
  grid-column: 1/3;
}

.estado-producao .helmet {
  grid-area: usuario;
  grid-row: 2/3;
  grid-column: 3/3;
}

.estado-producao .helmet_invisible {
  grid-area: usuario;
  grid-row: 2/3;
  grid-column: 3/3;
  opacity: 0;
}

.observacao {
  background-color: var(--color-toolbar-buttonsecondary);
  -ms-grid-column: 2;
}

.productionItemStateSent,
.productionItemStateSent > * {
  background: var(--color-productionstate-sent);
}

.observacao.atualizada,
.productionItemStateWaiting,
.observacao.atualizada > *,
.productionItemStateWaiting > * {
  background: var(--color-productionstate-waiting);
}

.productionItemStateProducing,
.productionItemStateProducing > * {
  background: var(--color-productionstate-producing);
}

.productionItemStateProduced,
.productionItemStateProduced > * {
  background: var(--color-productionstate-produced);
}

.productionItemStateLeftForDelivery,
.productionItemStateLeftForDelivery > * {
  background: var(--color-productionstate-left-for-delivery);
}

.productionItemStateDelivered,
.productionItemStateDelivered > * {
  background: var(--color-productionstate-delivered);
}

.productionItemStateClosed,
.productionItemStateClosed > * {
  background: var(--color-productionstate-closed);
  color: var(--color-text-light);
}

.productionItemStateCancelled,
.productionItemStateCancelled > * {
  background: var(--color-productionstate-cancelled);
  color: var(--color-text-light);
}

.productionItemStateDefault,
.productionItemStateDefault > * {
  background: var(--color-background);
}

/* Card do origem de venda */
.origem-venda > .card-content {
  display: grid;
  -ms-grid-rows: auto auto auto;
  -ms-grid-columns: 2fr 1fr;
      grid-template:  "nome pago"    auto
                      "nome enviado" auto
                      "codigo geral" auto / 2fr 1fr; 
}

.origem-venda > .card-content > .card-cell {
  display: grid;
  -ms-grid-rows: 1fr;
  -ms-grid-columns: 1fr auto;
      grid-template: "text numb" 1fr / auto auto;
  text-overflow: initial;
}

.origem-venda > .card-footer {
  background: var(--color-card-accordion-background);
  border-radius: var(--card-borda-raio);
}

.origem-venda .nome-origem {
  grid-area: nome;
  grid-row: 1/3;
  grid-column: 1/3;
}

.origem-venda .codigo-origem {
  grid-area: codigo;
  grid-row: 3/4;
}

.origem-venda .pago-origem {
  grid-area: pago;
  grid-column-start: 2;
}

.origem-venda .pago-origem > .NumberAlign {
  grid-column-start: 2;
}

.origem-venda .enviado-origem {
  grid-area: enviado;
  grid-column-start: 2;
  grid-row-start: 2;
}

.origem-venda .enviado-origem > .NumberAlign {
  grid-column-start: 2;
}

.origem-venda .geral-origem {
  grid-area: geral;
  grid-column-start: 2;
  grid-row-start: 3;
}

.origem-venda .geral-origem > .NumberAlign {
  grid-column-start: 2;
}

/* Card de grupo do produto */
.card.grupo-produto {
  min-height: 70px;
}

.card.grupo-produto > .card-content {
  padding: 2px;
  overflow: hidden;
  align-content: center;
  display: table;
}

.card.grupo-produto h1 {
  font-size: 22px;
  height: 70px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  line-height: 100%;
}

.venda-produto-container {
  padding: 0px 2px;
  margin: 0px 4px;
  background: var(--color-card-accordion-background);
  overflow: hidden;
  display: block;
  transition: all 0.2s ease-in-out;
}

.grupo-produto .accordion-content {
  padding: 0px 2px;
  margin: 0px 4px;
  background: var(--color-card-accordion-background);
  overflow: hidden;
  display: block;
  transition: all 0.2s ease-in-out;
}

.grupo-produto .accordion-item {
  padding: 4px 5px;
  margin-left: 2px;
  margin-right: 2px;
  background: var(--color-card-accordion-background);
  border-top-width: thin;
  border-bottom-width: thin;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: var(--color-dark-button);
  border-bottom-color: var(--color-dark-button);
}

.grupo-produto .accordion-item.desabilitado {
  opacity: 0.3;
}

.grupo-produto .accordion-item:first-child {
  margin-top: 2px;
  border-top-style: none;
}

.grupo-produto .accordion-item:last-child {
  margin-bottom: 2px;
  border-bottom-style: none;
}

.grupo-produto .accordion-item h2{
  font-size: calc(1rem + 0.5vw);
}

.grupo-produto .accordion-item .input-group {
  font-size: calc(1.2rem + 0.5vw);
}

.grupo-produto .accordion-item-cell {
  display: grid;
  -ms-grid-rows: 1fr 3px auto;
  -ms-grid-columns: 1fr 3px auto 3px auto 3px auto;
      grid-template: "nome nome nome valor" 1fr
                    ".    desc obse quant" auto / 1fr auto auto auto;
  -webkit-box-align: center;
        align-items: center;
  width: 100%;
  grid-gap: 3px;
}

.grupo-produto .accordion-item-cell img {
  width: 5em;
  height: 5em;
  border-radius: 10px;
  object-fit: cover;
}

.grupo-produto .accordion-item-cell > * {
  color: var(--color-text-dark);
}

.grupo-produto .accordion-item-cell > .nome-produto {
  grid-area: nome;

  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
      grid-column-start: 1;
      grid-column-end: 4;
    
  grid-row-start: 1;
}

.grupo-produto .accordion-item-cell > .preco-produto {
  grid-area: valor;

  -ms-grid-column: 7;
      grid-column-start: 4;
  
  grid-row-start: 1;
}

.grupo-produto .accordion-item-cell > .descricao-produto {
  grid-area: desc;

  -ms-grid-column: 3;
      grid-column: 2 / 3; 

  -ms-grid-row: 3;
      grid-row: 2 / 3;
}

.grupo-produto .descricao-produto {
  font-size: calc(1.2rem + 0.5vw);
}

.grupo-produto .accordion-item-cell.menu > .descricao-produto {
  grid-area: desc;

  -ms-grid-column: 3;
      grid-column: 3 / 4; 

  -ms-grid-row: 3;
      grid-row: 1 / 2;
}

.grupo-produto .accordion-item-cell > .observacao-produto {
  grid-area: obse;

  -ms-grid-column: 5;
      grid-column-start: 3;
  
  -ms-grid-row: 3;
      grid-row-start: 2;
}

.grupo-produto .observacao-produto {
  font-size: calc(1.2rem + 0.5vw);
}

.grupo-produto .accordion-item-cell button {
  background: none;
}

.grupo-produto .build-cell-from-tamanho {
  min-height: 2.5em;
  font-size: calc(1.58rem + 0.5vw);
  text-align: center;
  display: flex;
}

input[id*=amountInputField].quantidadeAlterada {
  color: var(--color-text-link);
  font-weight: normal;
}

/* Card de cliente */
.card.cliente {
  min-height: 70px;
}

.card.cliente > .card-content {
  padding: 2px;
  overflow: hidden;
  align-content: center;
  display: table;
}

.card.cliente h1 {
  font-size: 22px;
  height: 70px;
  overflow: hidden;
  display: table-cell;
  vertical-align: middle;
  line-height: 100%;
}

.card.cliente h2 {
  font-size: 18px;
  height: 20px;
  overflow: hidden;
  display: table-row;
  vertical-align: middle;
  line-height: 100%;
}

/* Card notificação item venda produzido */
.produced-item {
  white-space: nowrap;
}

.produced-item > .card-content {
  grid-template: auto auto auto/auto;
  display: grid;
}

.produced-item .nome-produto,
.produced-item .quantidade {
  grid-area: quantidade;
  grid-row: 1/2;
  grid-column: 1/2;
}

.produced-item .hora {
  text-align: right !important;
  grid-area: hora;
  grid-row: 3/4;
  grid-column: 3/4;
}

.produced-item > .card-content > .card-cell {
  display: grid;
  grid-row: 2/4;
  grid-column: 1/2;
  grid-template: auto auto/auto;
}
  
.produced-item .codigo-origem {
  grid-area: codigo-origem;
  grid-row: 2/3;
  grid-column: 1/2;
}
  
.produced-item .nome-venda {
  text-align: left !important;
  grid-area: nome-venda;
  grid-row: 3/4;
  grid-column: 1/2;
}

.produced-item .quantidade {
  grid-area: quantidade;
  grid-row: 1/2;
  grid-column: 3/4;
}

@media screen and (min-width: 35.5em) {
  .produced-item > .card-content {
    grid-template: auto auto/2fr 7fr 1fr;
  }

  .produced-item .hora {
    text-align: left !important;
    grid-area: hora;
    grid-row: 1/2;
    grid-column: 1/2;
  }

  .produced-item .nome-produto {
    grid-area: nome-produto;
    grid-row: 1/2;
    grid-column: 2/3;
  }

  .produced-item .quantidade {
    grid-area: quantidade;
    grid-row: 1/2;
    grid-column: 3/4;
  }

  .produced-item > .card-content > .card-cell {
    display: grid;
    grid-row: 2/3;
    grid-column: 1/4;
    grid-template: auto auto/1fr 1fr;
  }

  .produced-item .codigo-origem {
    grid-area: codigo-origem;
    grid-row: 2/3;
    grid-column: 1/2;
  }

  .produced-item .nome-venda {
    text-align: right !important;
    grid-area: nome-venda;
    grid-row: 2/3;
    grid-column: 2/3;
  }
}

/* Card notificação origem venda chamada */
.called-sale-source {
  white-space: nowrap;
  border-radius: var(--card-borda-raio);
}

.called-sale-source > .card-content {
  grid-template: 1fr auto/auto auto;
  display: grid;
}

.called-sale-source button {
  font-size: 0.7em;
  font-weight: 500;
  width: 3em;
  height: 2em;
  margin: 0 0.5em 0 0.8em;
}

.called-sale-source .codigo-origem {
  grid-row: 1/2;
  grid-column: 1/2;
}

.called-sale-source .data {
  grid-row: 3/3;
  grid-column: 1/2;
  text-align: left;
}

.called-sale-source .hora {
  grid-row: 2/3;
  grid-column: 1/2;
  text-align: left;
}

.called-sale-source .botao-ok {
  grid-row: 3/3;
  grid-column: 2/3;
  margin: auto 0 auto auto;
  color: var(--color-text-dark);
}

@media screen and (min-width: 90em) {
  .called-sale-source > .card-content {
    grid-template: auto/1fr auto auto auto;
  }

  .called-sale-source .codigo-origem {
    grid-row: 1/2;
    grid-column: 1/2;
  }
    
  .called-sale-source .data {
    grid-row: 1/2;
    grid-column: 2/3;
  }
    
  .called-sale-source .hora {
    grid-row: 1/2;
    grid-column: 3/4;
  }

  .called-sale-source .botao-ok {
    grid-row: 1/2;
    grid-column: 4/5;
    margin: 0 0.5em 0 0.8em;
  }
}

.card-container {
  border-radius: var(--default-border-radius-card);
  background-color: var(--color-card-background);
}
  
.card-container:focus,
.card-container:hover {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.card-container:focus .card-content > .ButtonAlign,
.card-container:hover .card-content > .ButtonAlign,
.card-container:focus .card-content .TextAlign,
.card-container:hover .card-content .TextAlign,
.card-container:focus .card-content .NumberAlign,
.card-container:hover .card-content .NumberAlign,
.card-container:focus .card-content > .h1,
.card-container:hover .card-content > .h1,
.card-container:focus .card-content > .h2,
.card-container:hover .card-content > .h2 {
  color: var(--color-text-button-focused) !important;
}

/* Chrome mobile antigo */
.card-container.ancient-chrome-mobile .left {
  float: left;
}

.card-container.ancient-chrome-mobile .right {
  float: right;
}

.card-container.ancient-chrome-mobile .row {
  width: 100%;
  display: inline-flex;
  padding: 0;
  margin: 0;
}

.card-container.ancient-chrome-mobile .column {
  padding: 0;
}

/* Movimento */
.card-container.ancient-chrome-mobile:focus > .card-content.sell-control > .column > h1,
.card-container.ancient-chrome-mobile:hover > .card-content.sell-control > .column > h1,
.card-container.ancient-chrome-mobile:focus > .card-content.sell-control > .column > h2,
.card-container.ancient-chrome-mobile:hover > .card-content.sell-control > .column > h2,
.card-container.ancient-chrome-mobile:focus > .card-content.sell-control > .column > h3,
.card-container.ancient-chrome-mobile:hover > .card-content.sell-control > .column > h3,
.card-container.ancient-chrome-mobile:focus > .card-content.sell-control > .column > .row,
.card-container.ancient-chrome-mobile:hover > .card-content.sell-control > .column > .row {
  color: var(--color-text-button-focused) !important;
}

/* Movimento */
.card-container.ancient-chrome-mobile > .card-content.sell-control > .column {
  padding-top: 5px;
  padding-top: 0;
  margin-bottom: 10px;
}

.card-container.ancient-chrome-mobile > .card-content.sell-control > .left {
  width: 65%;
}

.card-container.ancient-chrome-mobile > .card-content.sell-control > .right {
  width: 35%;
}

.card-container.ancient-chrome-mobile > .card-content.sell-control > .right > .row {
  float: right;
}

.card-container.ancient-chrome-mobile > .card-content.sell-control > .right > .row > h3 {
  float: right;
}

.card-container.ancient-chrome-mobile > .card-content.sell-control > .right > .row > h3.TextAlign {
  float: right;
  
  width: 30%;
}

.card-container.ancient-chrome-mobile > .card-content.sell-control > .right > .row > h3.NumberAlign {
  width: 55%;
}

/* Produção */
.card-container.ancient-chrome-mobile > .card-content.production > .row > .left {
  width: 80%;
}

.card-container.ancient-chrome-mobile > .card-content.production > .row > .right {
  display: inline-block;
  width: 20%;
}

.card-container.ancient-chrome-mobile > .card-content.production > .row > .right > .column {
  width: 50%;
}

.card-container.ancient-chrome-mobile > .card-content.production > .row > .right > .column > * {
  margin: 0;
}

.card-container.ancient-chrome-mobile > .card-content.production > .row > .right > .column > .ButtonAlign {
  margin-right: calc(13px - 1vw);
}

.card-container.ancient-chrome-mobile > .card-content.production > .row > .right > .column > .NumberAlign {
  margin-left: calc(13px - 1vw);
}

.card-container.ancient-chrome-mobile > .card-content.production > .card-cell > .row > .column {
  width: 50%;
}

/* Movimento - Tela de vendas */
.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row {
  display: inline-flex;
}

.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row.i-1 > .column {
  padding-right: 5px;
}

.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row.i-1 > .column.left {
  width: 170%;
}

.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row.i-1 > .column.right {
  width: 100%;
}

.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row.i-1 > .column.right > .NumberAlign {
  width: 100%;
  height: 20px;
}

.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row.i-2 > .column.left {
  width: 100%;
}

.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row.i-2 > .column.right {
  width: 100%;
  max-width: 140px;
}

.card-container.ancient-chrome-mobile > .card-content.build-cell-from-grupo-produto ~ .accordion-content .build-cell-from-produto > .row .column {
  display: inline-flex;
  flex-direction: row;
}

/* Movimento */
.card-container.ancient-chrome-mobile .card-footer.sell-control {
  display: inline-block;
  width: 92.5%;
  height: 32px;
}

.card-container.ancient-chrome-mobile .card-footer.sell-control > button {
  width: 100%;
  height: 32px;
}

.card-container.ancient-chrome-mobile .card-footer.sell-control > .accordion-content {
  background-color: #eee;
}

/* Produção */
.card-container.ancient-chrome-mobile .card-footer.production.row {
  width: 93%;
  margin-left: 3%;
}
  
.card-container.ancient-chrome-mobile .card-footer.production.row > .column {
  width: 50%;
  padding: 3px;
}

.card-template {
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;

  width: 100%;
}

.card-template > .item {
  display: inline-block;

  width: 100%;

  vertical-align: top;

  zoom: 1;

  letter-spacing: normal;
  word-spacing: normal;
  text-rendering: auto;
}

@media screen and (min-width: 48em) {
  .card-template > .item {
    width: 50%;
  }
}

@media screen and (min-width: 64em) {
  .card-template > .item {
    width: 33.3333%;
  }
}

.card-template > .item > .card {
  border: 0;

  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  border-radius: 0.3em;

  min-width: 15em;

  margin: 0.15em;
  padding: 0.5em;

  width: calc(100% - 0.15em*2 - 0.5em*2);
}

.card-template > .item > .card:focus,
.card-template > .item > .card:hover {
  background: var(--color-primary);

  color: white;
}

.card-template > .item > .card .check-box {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  background: white;

  width: 1em;
  height: 1em;

  border: 1px solid #aaa;
  border-radius: 0.1em;
  
  color: black;
}

.card-template > .item > .card .check-box > .selecionado {
  font-size: 0.8em;
}

.card-template > .item > .card .linha {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;

  margin: 0.1em;
}

.card-template > .item > .card .linha .alinhar-a-esquerda {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: flex-start;
  
  text-align: left;
}

.card-template > .item > .card .linha .alinhar-a-direita {
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  justify-content: flex-end;
  
  text-align: right;
}

.card-template > .item > .card .coluna {
  display: flex;
  flex: column nowrap;
  flex: 1 1 auto;
}

.card-template > .item > .card .coluna > .alinhar-a-esquerda {
  align-items: left;
  justify-items: center;
}

.card-template > .item > .card .coluna > .alinhar-a-direita {
  align-items: right;
  justify-items: center;
}