.ui-dialog {
  position: fixed;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;

  width: 100%;
  height: 100%;
  
  background: rgba(1, 1, 1, 0.5);
  z-index: 10;
}

.ui-dialog > .dialog-frame {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 40em;
  height: auto;
  
  color: var(--cor-do-dialog-texto);
  background: var(--cor-do-dialog);
  border: var(--dialog-borda);
  border-radius: var(--dialog-borda-raio);
  box-shadow: var(--dialog-borda-cor) 5px 5px 1px 0;
}

@media screen and (max-width: 500px) {
  .ui-dialog > .dialog-frame {
    max-width: 90% !important;
  }
}

@media screen and (min-width: 501px) {
  .ui-dialog > .dialog-frame {
    max-width: 90%;
  }
}

@media screen and (min-width: 50em) {
  .ui-dialog > .dialog-frame {
    max-width: 40em;
  }
}

.ui-dialog > .dialog-frame > div {
  width: 100%;
}

.ui-dialog > .dialog-frame .dialog-header {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  height: auto;
}

.ui-dialog > .dialog-frame .dialog-header > .dialog-header-title {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  text-align: center;

  width: 100%;
  height: auto;
  margin-top: 0.3em;

  font-family: 'Roboto';
  font-size: 1.2em;
  font-weight: 500;
}

.ui-dialog > .dialog-frame .dialog-header > .dialog-header-close {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 1.75em;
}

.ui-dialog > .dialog-frame .dialog-header > .dialog-header-close > .ui-botao {
  color: black;
  border-style: none;
  background: transparent;
  width: unset;
  height: unset;
}

.ui-dialog > .dialog-frame .dialog-content {
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 1em;
  height: auto;
  text-align: center;
}