.ui-dialog-instalacao {
  position: fixed;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
  background: rgba(1, 1, 1, 0.5);
  z-index: 10;
}

.ui-dialog-instalacao > .dialog-content {
  display: flex;
  flex-flow: column nowrap;
  flex: 0 1 auto;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  height: auto;
  padding: 1.2em;
  background: var(--cor-do-dialog);
  border: var(--dialog-borda);
  border-radius: var(--dialog-borda-raio);
  box-shadow: var(--dialog-borda-cor) 0.3em 0.3em;
  color: var(--cor-do-dialog-texto);
}

.ui-dialog-instalacao > .dialog-content > * {
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.ui-dialog-instalacao > .dialog-content > .dialog-content-mensagem {
    font-size: 1.2em;
}

.ui-dialog-instalacao > .dialog-content > .dialog-content-botoes {
  margin-top: 1em;
}

.ui-dialog-instalacao > .dialog-content > .dialog-content-botoes .ui-botao {
  width: 7em;
  height: 1.5em;
  margin: 0.4em;
}

.ui-dialog-instalacao > .dialog-content > .titulo-dialog {
  font-size: 1.2em;
  font-weight: 500;

  margin-bottom: 1.25em;
}