.tableHeaderControls {
  flex-wrap: nowrap !important;
  font-size: 0.8em;
  padding-top: 1em;
  text-align: center;
}

.tableHeaderControls button {
  background-color: transparent;
}

.sub-form .button-grid {
  padding: 10px 15vw;
  grid-row-gap: 5px;
}

.sub-form .button-grid > button {
  width: 100%;
}

#relatorio th {
  width: 5em;
  white-space: normal;
  word-wrap: break-word;
}

#relatorio td {
  width: 5em;
  white-space: normal;
  word-wrap: break-word;
}

#relatorio td.cinza {
  background-color: rgb(221, 217, 217);
}

#relatorio td.branco {
  background-color: white;
}

#relatorio #observacao {
  width: 9em;
}

#relatorio #nomeCliente,
#relatorio #produtoNome,
#relatorio #endereco {
  width: 7em;
}

#relatorio #tracejado {
  height: 1px;
  background: black;
  width: 100%;
}